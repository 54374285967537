import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Alert,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import { Timer as TimerIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext'; // 引入 AuthContext
const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Noto Sans SC', 'Noto Sans KR', 'Noto Sans JP', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
        },
      },
    },
  },
});

export default function LoginPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { login, isAuthenticated, isLoading } = useAuth();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');  
  const [snackbarOpen, setSnackbarOpen] = useState(false);  
  const [showForgotPassword, setShowForgotPassword] = useState(false);  
  const [isUnverified, setIsUnverified] = useState(false);
  const [unverifiedUsername, setUnverifiedUsername] = useState('');
  const [email, setEmail] = useState('');
  const [isForgotPasswordLoading, setIsForgotPasswordLoading] = useState(false);
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }

    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [isAuthenticated, navigate, i18n]);
 
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setError('');
    setSuccessMessage('');
    setShowForgotPassword(false);
    setIsUnverified(false);

    if (!username || !password) {
      setError(t('loginError.emptyFields'));
      return;
    }

    try {
      await login(username, password);
      setSuccessMessage(t('loginSuccess'));
      setSnackbarOpen(true);
      navigate('/dashboard');
    } catch (error) {
      if (error instanceof Error) {
        let parsedError;
        try {
          parsedError = JSON.parse(error.message);
        } catch (e) {
          parsedError = {message: error.message};
        }

        setError(parsedError.message);
        if (parsedError.message === '该帐号尚未验证成功') {
          setIsUnverified(true);
          setUnverifiedUsername(parsedError.username);
        } else if (parsedError.message === '用户不存在') {
          setShowForgotPassword(true);
        } else if (parsedError.message === '密码不正确') {
          setShowForgotPassword(true);
          setUnverifiedUsername(parsedError.username);
          setEmail(parsedError.email);
        } else {
          setError(t('loginError.unknown'));
        }
      } else {
        setError(t('loginError.unknown'));
      }
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleResendVerification = () => {
    navigate('/sendingVeriEmail', { state: { username: unverifiedUsername, email: email, fromPage: 'login' } });
    console.log(unverifiedUsername);
  };

  const handleForgotPassword = async () => {
    const lastRequestTime = localStorage.getItem('lastForgotPasswordRequestTime');
    const now = Date.now();
    const tenMinutes = 10 * 60 * 1000;

    if (lastRequestTime && now - parseInt(lastRequestTime, 10) < tenMinutes) {
      const remainingTime = Math.ceil((tenMinutes - (now - parseInt(lastRequestTime, 10))) / 60000);
      setError(`${t('loginError.tooManyRequests')} ${remainingTime} ${t('loginError.minutes')} ${t('loginError.laterTryAgain')}.`);
      return;
    }

    setIsForgotPasswordLoading(true);
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${apiBaseUrl}/api/resend-password-reset-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username: unverifiedUsername, email: email, language: i18n.language })
      });

      if (response.ok) {
        localStorage.setItem('lastForgotPasswordRequestTime', now.toString());
        navigate('/password-reset-sent', { state: { email: email, username: unverifiedUsername, language: i18n.language } });
      } else {
        const data = await response.json();
        setError(data.message || t('loginError.unknown'));
      }
    } catch (err) {
      setError(t('loginError.unknown'));
    }
    setIsForgotPasswordLoading(false);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: { xs: 2, sm: 3, md: 4 }, // 添加响应式内边距
          }}
        >
          <TimerIcon size={48} color={theme.palette.primary.main} />
          <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
            {t('login')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label={t('usernameOrEmail')}
              name="username"
              autoComplete="username"
              autoFocus
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('password')}
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            {error && (
              <Alert severity="error" sx={{ mt: 2 }}>
                {error}
              </Alert>
            )}

            {isUnverified && (
              <Box sx={{ mt: 2 }}>
                <Typography variant="body2" color="error" gutterBottom>
                  {t('accountNotVerified')}
                </Typography>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  onClick={handleResendVerification}
                  sx={{ mt: 1 }}
                >
                  {t('resendVerificationEmail')}
                </Button>
              </Box>
            )}
            {showForgotPassword && (
              <Button
                variant="contained"
                onClick={handleForgotPassword}
                disabled={isForgotPasswordLoading}
                sx={{ display: 'block', mt: 2, textAlign: 'center' }}
              >
                {isForgotPasswordLoading ? <CircularProgress size={24} /> : t('loginError.forgotPassword')}
              </Button>
            )}

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t('login')}
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link component={RouterLink} to="/" variant="body2">
                {t('backToHome')}
              </Link>
              <Link component={RouterLink} to="/register" variant="body2">
                {t('noAccount')}
              </Link>
            </Box>
          </Box>
        </Box>
        
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleSnackbarClose} severity="success" sx={{ width: '100%' }}>
            {successMessage}
          </Alert>
        </Snackbar>
      </Container>
    </ThemeProvider>
  );
}
