import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Typography, CircularProgress, Box, Snackbar, Alert } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../context/AuthContext'; // 引入 AuthContext

export default function VerifyEmailPage() {
  const [verificationStatus, setVerificationStatus] = useState<'verifying' | 'success' | 'error'>('verifying');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const { setUserInfo } = useAuth(); // 使用 AuthContext
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const verifyEmail = async () => {
      // 从 URL 中提取 token
      const tokenForEmailVerification = new URLSearchParams(location.search).get('token');
      console.log('Token from URL:', tokenForEmailVerification); // 添加日志

      if (!tokenForEmailVerification) {
        setVerificationStatus('error');
        setSnackbarMessage(t('verifyEmail.invalidToken'));
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
        return;
      }

      try {
        console.log('Sending verification request...'); // 添加日志
        // 发送 POST 请求到后端进行验证
        const response = await fetch(`${apiBaseUrl}/api/verify-email`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ 
            tokenForEmailVerification
           }),
        });

        console.log('Response status:', response.status); // 添加日志

        if (response.ok) {
          const data = await response.json();
          console.log('Response data:', data); // 添加日志

          // 保存 JWT 令牌
          localStorage.setItem('token', data.token);
          localStorage.setItem('user', JSON.stringify(data.user));
          // 更新 AuthContext 状态，使用表单中的数据
          setUserInfo({
            id: data.user.id,
            username: data.user.username,
            email: data.user.email,
            birthdate: data.user.birthdate, 
            isVerified: true,
          });

          setVerificationStatus('success');
          setSnackbarMessage(t('verifyEmail.success'));
          setSnackbarSeverity('success');
          setOpenSnackbar(true);
          // 等待 2 秒后跳转到 dashboard
          setTimeout(() => navigate('/'), 2000);
        } else {
          console.error('Verification failed:', await response.text()); // 添加错误日志
          setVerificationStatus('error');
          setSnackbarMessage(t('verifyEmail.failed'));
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
        }
      } catch (error) {
        console.error('Email verification error:', error); // 添加错误日志
        setVerificationStatus('error');
        setSnackbarMessage(t('verifyEmail.failed'));
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      }
    };

    verifyEmail();
  }, [navigate, location, t, apiBaseUrl, setUserInfo]); // 添加依赖项

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
      {verificationStatus === 'verifying' && (
        <>
          <CircularProgress />
          <Typography variant="h6" style={{ marginTop: 20 }}>
            {t('verifyEmail.verifying')}
          </Typography>
        </>
      )}
      {verificationStatus === 'success' && (
        <Typography variant="h6">
          {t('verifyEmail.success')}
        </Typography>
      )}
      {verificationStatus === 'error' && (
        <Typography variant="h6" color="error">
          {t('verifyEmail.error')}
        </Typography>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}