import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Container, Box, Typography, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function PasswordResetSentPage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const email = location.state?.email;
  const [countdown, setCountdown] = useState<number>(30);

  useEffect(() => {
    if (!email) {
      // 如果没有通过导航传递邮箱，重定向回首页
      navigate('/');
    } else {
      const storageKey = 'passwordResetCountdownEnd';
      let endTimeStr: string | null = sessionStorage.getItem(storageKey);
      let endTime: number;

      if (!endTimeStr) {
        // 设置倒计时结束时间为当前时间加30秒
        endTime = Date.now() + 30 * 1000;
        sessionStorage.setItem(storageKey, endTime.toString());
      } else {
        endTime = Number(endTimeStr);
        // 检查转换是否成功
        if (isNaN(endTime)) {
          endTime = Date.now() + 30 * 1000;
          sessionStorage.setItem(storageKey, endTime.toString());
        }
      }

      const updateCountdown = () => {
        const newRemainingTime = Math.max(Math.floor((endTime - Date.now()) / 1000), 0);
        setCountdown(newRemainingTime);

        if (newRemainingTime <= 0) {
          clearInterval(timer);
          sessionStorage.removeItem(storageKey);
          navigate('/');
        }
      };

      updateCountdown(); // 初始调用以即时更新

      const timer = setInterval(updateCountdown, 1000);

      return () => clearInterval(timer);
    }
  }, [email, navigate]);

  if (!email) {
    return null;
  }

  return (
    <Container component="main" maxWidth="sm">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {t('PasswordResetSent.title')}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          {t('PasswordResetSent.message')} <strong>{email}</strong>. {t('PasswordResetSent.instruction')}
        </Typography>
        <Typography variant="body2" sx={{ mt: 2 }}>
          {t('PasswordResetSent.countdown')} <strong>{countdown}</strong> {t('PasswordResetSent.seconds')}.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          sx={{ mt: 4 }}
          onClick={() => navigate('/')}
        >
          {t('PasswordResetSent.backToHome')}
        </Button>
      </Box>
    </Container>
  );
}