'use client'

import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  Typography,
  Button,
  Container,
  Box,
  TextField,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  ThemeProvider,
  createTheme,
  CssBaseline,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Noto Sans SC', 'Noto Sans KR', 'Noto Sans JP', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
        },
      },
    },
  },
});

interface Post {
  id: string;
  content: string;
  created_at: string;
  upvotes: number;
  downvotes: number;
  userVote: 'up' | 'down' | null; // 增加用户的投票状态
}

const PostManagementPage: React.FC = () => {
  const { userInfo, isAuthenticated } = useContext(AuthContext) || {};
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [posts, setPosts] = useState<Post[]>([]);
  const [filteredPosts, setFilteredPosts] = useState<Post[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedPosts, setSelectedPosts] = useState<string[]>([]);
  const [page, setPage] = useState(1);  // 新增分页状态
  const limit = 10;  // 每页的帖子数量

  const fetchPosts = useCallback(async () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };

    const token = localStorage.getItem('token');
    if (token && isAuthenticated) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    try {
      const response = await axios.get(`${apiBaseUrl}/api/user/posts`, {
        headers,
        params: {
          page,  // 增加分页
          limit,
          userId: userInfo?.id,
        },
      });

      const userPosts = response.data; // 后端已经过滤过
      setPosts(userPosts);
      setFilteredPosts(userPosts);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  }, [page, limit, userInfo?.id, isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) {
      fetchPosts();
    }
  }, [isAuthenticated, page, fetchPosts]);  // 添加 fetchPosts 到依赖数组

  useEffect(() => {
    if (isAuthenticated) {
      fetchPosts();
    }
  }, [isAuthenticated, page, fetchPosts]);  // 添加 fetchPosts 到依赖数组

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    filterPosts(term, selectedDate);
  };

  const handleDateChange = (date: Date | null) => {
    setSelectedDate(date);
    filterPosts(searchTerm, date);
  };

  const filterPosts = (term: string, date: Date | null) => {
    let filtered = posts.filter((post) =>
      post.content.toLowerCase().includes(term)
    );

    if (date) {
      filtered = filtered.filter((post) => {
        const postDate = new Date(post.created_at);
        return (
          postDate.getFullYear() === date.getFullYear() &&
          postDate.getMonth() === date.getMonth()
        );
      });
    }

    setFilteredPosts(filtered);
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>, postId: string) => {
    if (event.target.checked) {
      setSelectedPosts([...selectedPosts, postId]);
    } else {
      setSelectedPosts(selectedPosts.filter((id) => id !== postId));
    }
  };

  const handleBulkDelete = async () => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const token = localStorage.getItem('token');
  
      await axios.delete(`${apiBaseUrl}/api/posts/delete`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        data: {
          postIds: selectedPosts
        }
      });
  
      fetchPosts();
      setSelectedPosts([]);
      setPage(page + 1);
    } catch (error) {
      console.error('Error deleting posts:', error);
    }
  };
  

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
        <Button variant="contained" color="primary" onClick={handleBackClick} sx={{ mb: 2 }}>
          {t('backToDashboard')}
        </Button>
        <Typography variant="h4" component="h1" gutterBottom>
          {t('managePosts')}
        </Typography>
        <Box sx={{ mb: 2, display: 'flex', gap: 2 }}>
          <TextField
            label={t('searchPosts')}
            variant="outlined"
            value={searchTerm}
            onChange={handleSearch}
            sx={{ flexGrow: 1 }}
          />
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="yyyy-MM"
            showMonthYearPicker
            customInput={
              <TextField
                label={t('filterByMonth')}
                variant="outlined"
              />
            }
          />
        </Box>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleBulkDelete}
          disabled={selectedPosts.length === 0}
          sx={{ mb: 2 }}
        >
          {t('deleteSelectedPosts')}
        </Button>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    indeterminate={selectedPosts.length > 0 && selectedPosts.length < filteredPosts.length}
                    checked={selectedPosts.length === filteredPosts.length}
                    onChange={(event) =>
                      event.target.checked
                        ? setSelectedPosts(filteredPosts.map((post) => post.id))
                        : setSelectedPosts([])
                    }
                  />
                </TableCell>
                <TableCell>{t('content')}</TableCell>
                <TableCell>{t('createdAt')}</TableCell>
                <TableCell>{t('upvotes')}</TableCell>
                <TableCell>{t('downvotes')}</TableCell>
                <TableCell>{t('userVote')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredPosts.map((post) => (
                <TableRow key={post.id}>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedPosts.includes(post.id)}
                      onChange={(event) => handleCheckboxChange(event, post.id)}
                    />
                  </TableCell>
                  <TableCell>{post.content}</TableCell>
                  <TableCell>{new Date(post.created_at).toLocaleString()}</TableCell>
                  <TableCell>{post.upvotes}</TableCell>
                  <TableCell>{post.downvotes}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Container>
    </ThemeProvider>
  );
};

export default PostManagementPage;

