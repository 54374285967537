import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
  Link,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Select,
  MenuItem,
  SelectChangeEvent,
  Grid,
  IconButton,
  Menu,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { Timer as TimerIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { useMediaQuery } from '@mui/material';

// 定义主题
const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Noto Sans SC', 'Noto Sans KR', 'Noto Sans JP', sans-serif",
    h2: {
      fontSize: '2rem',
      '@media (min-width:600px)': {
        fontSize: '3rem',
      },
    },
    h5: {
      fontSize: '1.5rem',
      '@media (min-width:600px)': {
        fontSize: '2rem',
      },
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
          padding: '8px 16px',
          '@media (min-width:600px)': {
            padding: '12px 24px',
          },
        },
      },
    },
  },
});

const languages = [
  { code: 'en', name: 'English' },
  { code: 'zh', name: '中文' },
  { code: 'ko', name: '한국어' },
  { code: 'ja', name: '日本語' },
  { code: 'es', name: 'Español' },
];

const LandingPage: React.FC = () => {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState<string>(i18n.language);
  const navigate = useNavigate();
  const { isAuthenticated, userInfo, logout } = useAuth();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(anchorEl);

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language') || i18n.language;
    if (savedLanguage !== i18n.language) {
      i18n.changeLanguage(savedLanguage);
    }
    setLanguage(savedLanguage);
  }, [i18n]);

  useEffect(() => {
    setLanguage((prev) => prev);
  }, [isAuthenticated]);

  const handleLanguageChange = (event: SelectChangeEvent) => {
    const newLang = event.target.value;
    setLanguage(newLang);
    i18n.changeLanguage(newLang);
    localStorage.setItem('language', newLang);
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderMenuButtons = () => {
    if (isAuthenticated) {
      return (
        <>
          <Typography variant="body1" sx={{ mr: 2, display: isMobile ? 'none' : 'block' }}>
            {userInfo?.username || 'User'}
          </Typography>
          <Button color="inherit" onClick={handleLogout} size={isMobile ? 'small' : 'medium'}>
            {t('logout')}
          </Button>
        </>
      );
    } else {
      return (
        <>
          <Button color="inherit" component={RouterLink} to="/login" size={isMobile ? 'small' : 'medium'}>
            {t('login')}
          </Button>
          <Button color="inherit" component={RouterLink} to="/register" size={isMobile ? 'small' : 'medium'}>
            {t('register')}
          </Button>
          <Button color="inherit" component={RouterLink} to="/guest-browse" size={isMobile ? 'small' : 'medium'}>
            {t('guestBrowse')}
          </Button>
        </>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            <Box sx={{ mr: 2 }}>
              <TimerIcon size={isMobile ? 24 : 32} />
            </Box>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              TimeMessager
            </Typography>
            {isMobile ? (
              <>
                <IconButton
                  size="large"
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={handleMenuOpen}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  open={isMenuOpen}
                  onClose={handleMenuClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <Box sx={{ px: 2, py: 1 }}>
                    <Select
                      value={language}
                      onChange={(event) => {
                        handleLanguageChange(event);
                        handleMenuClose();
                      }}
                      fullWidth
                    >
                      {languages.map((lang) => (
                        <MenuItem key={lang.code} value={lang.code}>
                          {lang.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  {isAuthenticated ? (
                    <>
                      <MenuItem onClick={() => { handleMenuClose(); handleLogout(); }}>
                        {t('logout')}
                      </MenuItem>
                    </>
                  ) : (
                    <>
                      <MenuItem component={RouterLink} to="/login" onClick={handleMenuClose}>
                        {t('login')}
                      </MenuItem>
                      <MenuItem component={RouterLink} to="/register" onClick={handleMenuClose}>
                        {t('register')}
                      </MenuItem>
                      <MenuItem component={RouterLink} to="/guest-browse" onClick={handleMenuClose}>
                        {t('guestBrowse')}
                      </MenuItem>
                    </>
                  )}
                </Menu>
              </>
            ) : (
              <>
                <Select
                  value={language}
                  onChange={handleLanguageChange}
                  sx={{ mr: 2, minWidth: 100 }}
                  size={isMobile ? 'small' : 'medium'}
                >
                  {languages.map((lang) => (
                    <MenuItem key={lang.code} value={lang.code}>
                      {lang.name}
                    </MenuItem>
                  ))}
                </Select>
                {renderMenuButtons()}
              </>
            )}
          </Toolbar>
        </AppBar>

        <Container
          component="main"
          sx={{
            mt: 4,
            mb: 2,
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            px: isMobile ? 2 : 0,
          }}
        >
          <Grid container spacing={isMobile ? 2 : 4} direction="column" alignItems="center">
            <Grid item>
              <Typography variant="h2" component="h1" gutterBottom align="center">
                TimeMessager
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" component="h2" gutterBottom align="center">
                {t('slogan')}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="body1" sx={{ mt: 2, mb: 4, maxWidth: 600, textAlign: 'center' }}>
                {t('description')}
              </Typography>
            </Grid>
            <Grid item>
              {isAuthenticated ? (
                <Button
                  variant="contained"
                  size={isMobile ? 'medium' : 'large'}
                  color="primary"
                  component={RouterLink}
                  to="/dashboard"
                  state={{ isGuest: false, birthdate: userInfo?.birthdate, language: language, username: userInfo?.username }}
                >
                  {t('viewFeed')}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size={isMobile ? 'medium' : 'large'}
                  color="primary"
                  component={RouterLink}
                  to="/register"
                >
                  {t('getStarted')}
                </Button>
              )}
            </Grid>
          </Grid>
        </Container>

        <Box component="footer" sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: (theme) => theme.palette.grey[200] }}>
          <Container maxWidth="sm">
            <Typography variant="body2" color="text.secondary" align="center">
              {'© '}
              {new Date().getFullYear()}
              {' TimeMessager | '}
              <Link color="inherit" href="#">
                {t('privacyPolicy')}
              </Link>
              {' | '}
              <Link color="inherit" href="#">
                {t('termsOfService')}
              </Link>
            </Typography>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default LandingPage;