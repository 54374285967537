'use client'

import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Box, 
  Paper, 
  Typography, 
  Button, 
  CircularProgress 
} from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EmailIcon from '@mui/icons-material/Email';
import { useTranslation } from 'react-i18next';

export default function SendingVeriEmail() {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { username, fromPage, language } = location.state || {};

  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendMessage, setResendMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [countdown, setCountdown] = useState(0);

  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const savedStatus = localStorage.getItem('resendEmailStatus');
    if (savedStatus) {
      const { isDisabled, endTime } = JSON.parse(savedStatus);
      const remainingTime = calculateRemainingTime(endTime);
      setIsResendDisabled(isDisabled);
      setCountdown(remainingTime);

      if (remainingTime > 0) {
        startCountdown(remainingTime);
      }
    } else if (fromPage === 'register') {
      setIsResendDisabled(true);
      setCountdown(120);
      startCountdown(120);
    }
  }, [fromPage]);

  const calculateRemainingTime = (endTime: number) => {
    const now = Date.now();
    return Math.max(Math.floor((endTime - now) / 1000), 0);
  };

  const startCountdown = (seconds: number) => {
    const endTime = Date.now() + seconds * 1000;
    localStorage.setItem('resendEmailStatus', JSON.stringify({
      isDisabled: true,
      endTime,
    }));

    const interval = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown <= 1) {
          clearInterval(interval);
          setIsResendDisabled(false);
          localStorage.removeItem('resendEmailStatus');
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(interval);
  };

  const handleResend = async () => {
    setIsLoading(true);
    setIsResendDisabled(true);

    try {
      const token = localStorage.getItem('token');
      const response = await fetch(`${apiBaseUrl}/api/resend-verification-email`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ username, language })
      });

      if (response.ok) {
        setResendMessage(t('sendingVeriEmail.emailResent'));
        startCountdown(120);
      } else {
        setResendMessage(t('sendingVeriEmail.resendFailed'));
      }
    } catch (error) {
      setResendMessage(t('sendingVeriEmail.resendFailed'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleBackToHome = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="sm">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100vh',
        }}
      >
        <Paper elevation={3} sx={{ p: 4, width: '100%', textAlign: 'center' }}>
          <CheckCircleOutlineIcon sx={{ fontSize: 60, color: 'success.main', mb: 2 }} />
          <Typography variant="h4" gutterBottom>
            {t('sendingVeriEmail.registrationSuccess')}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            {fromPage === 'register' 
              ? t('sendingVeriEmail.emailSentMessage') 
              : t('sendingVeriEmail.clickToSendEmail')}
          </Typography>
          <Typography variant="body1" sx={{ mt: 2, mb: 3 }}>
            {t('sendingVeriEmail.checkEmailInstructions')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mb: 2 }}>
            <EmailIcon sx={{ mr: 1, color: 'text.secondary' }} />
            <Typography variant="body2" color="text.secondary">
              {t('sendingVeriEmail.checkSpamFolder')}
            </Typography>
          </Box>
          <Button
            variant="contained"
            color="primary"
            onClick={handleResend}
            disabled={isResendDisabled || isLoading}
            sx={{ mt: 2 }}
          >
            {isLoading ? <CircularProgress size={24} /> : t('sendingVeriEmail.resendEmailButton', { time: countdown })}
          </Button>
          {resendMessage && (
            <Typography variant="body2" color="text.secondary" sx={{ mt: 2 }}>
              {resendMessage}
            </Typography>
          )}
          <Button
            variant="outlined"
            color="primary"
            onClick={handleBackToHome}
            sx={{ mt: 2 }}
          >
            {t('sendingVeriEmail.backToHome')}
          </Button>
        </Paper>
      </Box>
    </Container>
  );
}
