import React, { useEffect, useState, MouseEvent } from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/system';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface DaysLivedData {
  day: number;
  postCount: number;
  lastPostTimestamp: string;
  lastPostUser: string; // 新增字段
}

const ProgressBarContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: '30px',
  backgroundColor: '#e0e0e0',
  borderRadius: '15px',
  overflow: 'hidden',
  cursor: 'pointer',
}));

const MarkedDay = styled(Box)<{ scale: number }>(({ theme, scale }) => ({
  position: 'absolute',
  top: 0,
  width: `${2 * scale}px`, // 根据缩放比例调整宽度
  height: '100%',
  backgroundColor: `rgba(76, 175, 80, ${0.6 + 0.2 * scale})`, // 根据缩放比例调整透明度
  cursor: 'pointer',
  transition: 'all 0.2s ease-out',
  transform: 'translateX(-50%)', // 使标记居中对齐
}));

const DaysLivedProgressBar: React.FC = () => {
  const { t } = useTranslation();
  const [daysLivedData, setDaysLivedData] = useState<DaysLivedData[]>([]);
  const [cursorPos, setCursorPos] = useState<number | null>(null); // 光标位置百分比
  const maxDays = 44694; // 调整为更大的值以减少重叠

  useEffect(() => {
    const fetchDaysLivedData = async () => {
      try {
        const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
        const response = await axios.get(`${apiBaseUrl}/api/daysLivedData`);
        setDaysLivedData(response.data.daysLivedData);
      } catch (error) {
        console.error(t('fetchError'), error);
      }
    };

    fetchDaysLivedData();
  }, [t]);

  // 处理鼠标移动事件
  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    const boundingRect = (e.target as HTMLDivElement).getBoundingClientRect();
    const relativeX = e.clientX - boundingRect.left;
    const percent = (relativeX / boundingRect.width) * 100;
    setCursorPos(percent);
  };

  // 处理鼠标离开事件
  const handleMouseLeave = () => {
    setCursorPos(null);
  };

  return (
    <Box sx={{ width: '100%', p: 2 }}>
      <Typography variant="h6" gutterBottom>
        {t('lifeTimeline')}
      </Typography>
      <ProgressBarContainer onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
        {daysLivedData.map((data) => {
          const positionPercent = (data.day / maxDays) * 100;
          let scale = 1; // 默认缩放比例

          if (cursorPos !== null) {
            const distance = Math.abs(positionPercent - cursorPos);
            const threshold = 2; // 缩放阈值，单位百分比
            if (distance < threshold) {
              scale = 1 + (threshold - distance) / threshold; // 越近，缩放越大
            }
          }

          return (
            <Tooltip
              key={data.day}
              title={
                <React.Fragment>
                  <Typography variant="subtitle2">{t('day', { day: data.day })}</Typography>
                  <Typography variant="body2">{t('postCount', { count: data.postCount })}</Typography>
                  <Typography variant="body2">{t('lastPostTime', { time: new Date(data.lastPostTimestamp).toLocaleDateString() })}</Typography>
                  <Typography variant="body2">最近的post由用户 {data.lastPostUser} 于 {new Date(data.lastPostTimestamp).toLocaleDateString()} 发布</Typography> {/* 修改行 */}
                </React.Fragment>
              }
              arrow
            >
              <MarkedDay sx={{ left: `${positionPercent}%` }} scale={scale} />
            </Tooltip>
          );
        })}
      </ProgressBarContainer>
    </Box>
  );
};

export default DaysLivedProgressBar;