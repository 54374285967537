// TL/time_leftover/src/pages/guestlogin/GuestBrowsePage.tsx

import React, { useState, useEffect } from 'react';
import {
  Container,
  Box,
  Typography,
  Button,
  Link,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Alert,
  Paper,
  TextField,
  InputAdornment,
} from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Timer as TimerIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom'; // 引入 useNavigate
import { useAuth } from '../../context/AuthContext'; // 引入 AuthContext
import moment from 'moment';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday'; // 引入 CalendarTodayIcon

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Noto Sans SC', 'Noto Sans KR', 'Noto Sans JP', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
        },
      },
    },
  },
});

export default function GuestBrowsePage() {
  const { t, i18n } = useTranslation();
  const [birthdate, setBirthdate] = useState<Date | null>(null);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const { browseAsGuest } = useAuth(); // 使用 AuthContext 的方法

  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    const savedBirthdate = localStorage.getItem('guestBirthdate');
    
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
    
    if (savedBirthdate) {
      setBirthdate(new Date(savedBirthdate));
    }
  }, [i18n]);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!birthdate) {
      setError(t('guestBrowseError.birthdateRequired'));
      return;
    }

    const today = moment().startOf('day');
    const birthdateMoment = moment(birthdate).startOf('day');

    if (birthdateMoment.isAfter(today)) {
      setError(t('guestBrowseError.futureBirthdate'));
      return;
    }

    if (birthdateMoment.isBefore(moment('1850-01-01'))) {
      setError(t('guestBrowseError.pastBirthdate'));
      return;
    }

    const lifeDays = today.diff(birthdateMoment, 'days');
    localStorage.setItem('guestBirthdate', birthdateMoment.toISOString());

    // 使用 AuthContext 的 browseAsGuest
    browseAsGuest(lifeDays);
    navigate('/dashboard', { state: { lifeDays } });
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <TimerIcon size={48} color={theme.palette.primary.main} />
          <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
            {t('guestBrowse')}
          </Typography>
          <Paper elevation={3} sx={{ mt: 3, p: 3, width: '100%' }}>
            <Alert severity="info" sx={{ mb: 2 }}>
              {t('guestBrowseWarning')}
            </Alert>
            <Box component="form" onSubmit={handleSubmit} noValidate>
              <DatePicker
                selected={birthdate}
                onChange={(date: Date) => {
                  setBirthdate(date);
                  setError(null);
                }}
                dateFormat="yyyy-MM-dd"
                placeholderText={t('birthdate')}
                customInput={
                  <TextField
                    fullWidth
                    required
                    error={!!error}
                    helperText={error}
                    margin="normal"
                    label={t('birthdate')}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
              <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                {t('startBrowsing')}
              </Button>
            </Box>
          </Paper>
          <Box sx={{ mt: 2 }}>
            <Link href="/" variant="body2" sx={{ mr: 2 }}>
              {t('backToHome')}
            </Link>
            <Link href="/register" variant="body2">
              {t('createAccount')}
            </Link>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
