// pages/register/RegisterPage.tsx
import React, { useState, useEffect } from 'react';
import { useNavigate, Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Box,
  Typography,
  TextField,
  Button,
  Link,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Checkbox,
  FormControlLabel,
  Grid,
  Snackbar,
  Alert,
} from '@mui/material';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { isAfter, isBefore, parseISO } from "date-fns";
import { Timer as TimerIcon } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import CircularProgress from '@mui/material/CircularProgress';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Noto Sans SC', 'Noto Sans KR', 'Noto Sans JP', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
        },
      },
    },
  },
});

export default function RegisterPage() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [birthdateLocal, setBirthdateLocal] = useState<Date | null>(null);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [isLoading, setIsLoading] = useState(false); // 添加加载状态    
  const [isUsernameUnique] = useState(true);
  const [isEmailUnique] = useState(true);



  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL

  useEffect(() => {
  }, [apiBaseUrl]);

  // 加载保存的语言设置
  useEffect(() => {
    const savedLanguage = localStorage.getItem('language');
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    }
  }, [i18n]);

  // 验证表单是否有效
  const validateForm = () => {
    const newErrors: { [key: string]: string } = {};

    if (!username) newErrors.username = t('registerError.usernameRequired');
    if (!isUsernameUnique) newErrors.username = t('registerError.usernameTaken');

    if (!email) newErrors.email = t('registerError.emailRequired');
    else if (!/\S+@\S+\.\S+/.test(email)) newErrors.email = t('registerError.invalidEmail');
    if (!isEmailUnique) newErrors.email = t('registerError.emailTaken');

    if (!password) newErrors.password = t('registerError.passwordRequired');
    if (password !== confirmPassword) newErrors.confirmPassword = t('registerError.passwordMismatch');

    if (!birthdateLocal) newErrors.birthdate = t('registerError.birthdateRequired');
    else if (isAfter(birthdateLocal, new Date())) newErrors.birthdate = t('registerError.birthdateFuture');
    else if (isBefore(birthdateLocal, parseISO('1850-01-01'))) newErrors.birthdate = t('registerError.birthdateTooEarly');
    if (!agreeTerms) newErrors.agreeTerms = t('registerError.agreeTermsRequired');

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
 
  // 处理表单提交
  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (validateForm()) {
      setIsLoading(true); // 开始加载
      try {
        console.log('User language:', i18n.language);
        const response = await fetch(`${apiBaseUrl}/api/register`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            username,
            email,
            password,
            birthdate: birthdateLocal ? birthdateLocal.toLocaleDateString('en-CA') : '', // 使用本地日期字符串
            isVerified: false,
            language: i18n.language,
          }),
        });
        const data = await response.json();


        if (response.ok) {
          if (data.token) { // 仅检查 token

            // 跳转到注册成功页面
            navigate('/sendingVeriEmail', { state: { username, fromPage: 'register', language: i18n.language } });
          } else {
            console.error('Token missing in response'); // 添加错误日志
            setSnackbarMessage(t('registerError.invalidResponse'));
            setSnackbarSeverity('error');
            setOpenSnackbar(true);
          }
        } else {
          console.error('Registration failed:', data.message); // 添加错误日志
          setSnackbarMessage(data.message || t('registerError.failed'));
          setSnackbarSeverity('error');
          setOpenSnackbar(true);
        }
      } catch (error) {
        console.error('Registration error:', error); // 添加错误日志
        setSnackbarMessage(t('registerError.failed'));
        setSnackbarSeverity('error');
        setOpenSnackbar(true);
      } finally {
        setIsLoading(false); // 结束加载
      }
    }
  };

  const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackbar(false);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            px: { xs: 2, sm: 3, md: 4 }, // 添加响应式内边距
          }}
        >
          <TimerIcon size={48} color={theme.palette.primary.main} />
          <Typography component="h1" variant="h5" sx={{ mt: 2 }}>
            {t('register')}
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="username"
                  label={t('username')}
                  name="username"
                  autoComplete="username"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  error={!!errors.username}
                  helperText={errors.username || (!isUsernameUnique && t('registerError.usernameTaken'))}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label={t('email')}
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  error={!!errors.email}
                  helperText={errors.email || (!isEmailUnique && t('registerError.emailTaken'))}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label={t('password')}
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  error={!!errors.password}
                  helperText={errors.password}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label={t('confirmPassword')}
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword}
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                <DatePicker
                  selected={birthdateLocal}
                  onChange={(date: Date) => setBirthdateLocal(date)}
                  dateFormat="yyyy-MM-dd"
                  maxDate={new Date()}
                  minDate={new Date('1850-01-01')}
                  placeholderText={t('birthdate')}
                  customInput={
                    <TextField
                      fullWidth
                      required
                      error={!!errors.birthdate}
                      helperText={errors.birthdate || t('birthdateWarning')}
                      sx={{ width: '100%' }} // 确保在移动设备上宽度适应
                    />
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={<Checkbox value="agreeTerms" color="primary" checked={agreeTerms} onChange={(e) => setAgreeTerms(e.target.checked)} />}
                  label={
                    <Typography variant="body2">
                      {t('agreeTerms')} <Link href="#">{t('privacyPolicy')}</Link> {t('and')} <Link href="#">{t('termsOfService')}</Link>
                    </Typography>
                  }
                />
                {errors.agreeTerms && <Typography color="error" variant="caption">{errors.agreeTerms}</Typography>}
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={isLoading} // 禁用按钮在加载时
            >
              {isLoading ? <CircularProgress size={24} /> : t('register')} 
            </Button>

            <Box sx={{ width: '100%', mt: 2 }}>
              <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleCloseSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              >
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
                  {snackbarMessage}
                </Alert>
              </Snackbar>
            </Box>

            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link component={RouterLink} to="/login" variant="body2">
                  {t('alreadyHaveAccount')}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
