// pages/dashboard/Dashboard.tsx  

'use client'

import React, { useState, useEffect, useCallback } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Avatar,
  Menu,
  MenuItem,
  Card,
  CardContent,
  CardHeader,
  CardActions,
  IconButton,
  Box,
  Container,
  Grid,
  Paper,
  List,
  ListItem,
  Divider,
  TextField,
  Snackbar,
} from '@mui/material'
import {
  Timer as TimerIcon,
  ArrowDropDown,
  Add as AddIcon,
  ThumbUp,
  ThumbDown,
  Person,
  Settings,
  Logout,
  Message as MessageIcon,
  Close as CloseIcon,
} from '@mui/icons-material'
import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import moment from 'moment'; // 确保已安装 moment.js
import { useAuth } from '../../context/AuthContext';
import DaysLivedProgressBar from '../../components/DaysLivedProgressBar';

interface Post {
  id: number
  User: {
    username: string
  } | null
  avatar: string
  content: string
  upvotes: number
  downvotes: number
  userVote: 'up' | 'down' | null
  created_at: string
}

const POSTS_PER_PAGE = 3
const MAX_POSTS_PER_DAY = 3

const StyledFeed = styled(Box)(({ theme }) => ({
  height: 'calc(100vh - 64px)',
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '0.4em'
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
    webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'rgba(0,0,0,.1)',
    outline: '1px solid slategrey'
  }
}))

export default function Dashboard() {
  const { isAuthenticated, isGuest, userInfo, isLoading, birthdate, setUserInfo, setIsAuthenticated, setBirthdate, logout } = useAuth();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [daysLived, setDaysLived] = useState(location.state?.lifeDays || 0);
  const [showFeed, setShowFeed] = useState(false);
  const [posts, setPosts] = useState<Post[]>([]);
  const [page, setPage] = useState(1);
  const [postsRemaining, setPostsRemaining] = useState(MAX_POSTS_PER_DAY);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [showPostForm, setShowPostForm] = useState(false);
  const [postContent, setPostContent] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    if (birthdate) {
      const calculateDaysLived = () => {
        const today = moment().startOf('day');
        const birthdateMoment = moment(birthdate).startOf('day');
        const days = today.diff(birthdateMoment, 'days');
        setDaysLived(days);
      };

      calculateDaysLived();

      const midnightUpdate = setInterval(calculateDaysLived, 24 * 60 * 60 * 1000);

      return () => clearInterval(midnightUpdate);
    } else {
      setDaysLived(0);
    }
  }, [birthdate]);

  // 在组件卸载时清除状态
  useEffect(() => {
    return () => {
      setDaysLived(0);
      setPosts([]);
      setPage(1);
      setPostsRemaining(MAX_POSTS_PER_DAY);
    };
  }, []);

  // 确保在组件加载时从 AuthContext 获取用户信息
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      setUserInfo(user);
      setIsAuthenticated(true);
      setBirthdate(new Date(user.birthdate));
    }
  }, [setUserInfo, setIsAuthenticated, setBirthdate]);

  useEffect(() => {
    const state = location.state as { isGuest: boolean; birthdate: Date; language: string; username: string } | null;
    const savedLanguage = localStorage.getItem('language');
    if (state) {
      if (state.language) {
        i18n.changeLanguage(state.language);
        localStorage.setItem('language', state.language); // 保存语言设置
      }
    } else if (savedLanguage) {
      i18n.changeLanguage(savedLanguage); // 加载保存的语言设置
    } else {
      const defaultLanguage = 'en'; // 默认语言
      i18n.changeLanguage(defaultLanguage);
      localStorage.setItem('language', defaultLanguage); // 保存默认语言设置
    }
  }, [location, i18n]);

  const user = {
    name: isAuthenticated ? userInfo?.username : t('guest'),
    avatar: '/placeholder.svg?height=40&width=40',
  }
  const fetchPostCount = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('未找到令牌');
      return;
    }
    
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
      const response = await fetch(`${apiBaseUrl}/api/user/posts/today`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (!response.ok) {
        throw new Error(`获取帖子数量失败: ${response.statusText}`);
      }
  
      const data = await response.json();
      setPostsRemaining(Math.max(0, MAX_POSTS_PER_DAY - data.count));
    } catch (error) {
      console.error('获取帖子数量失败:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchPostCount();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (!isLoading && !isGuest && !isAuthenticated) {
      navigate('/'); // 如果不是游客也不是注册用户，重定向到首页
    }
  }, [isLoading, isGuest, isAuthenticated, navigate]);

  const fetchPosts = useCallback(async () => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };
  
    const token = localStorage.getItem('token');
    const queryParams: Record<string, string> = {
      page: String(page),
      limit: String(POSTS_PER_PAGE),
    };
  
    let currentDaysLived: number;

    if (isAuthenticated && token) {
        headers['Authorization'] = `Bearer ${token}`;
        // 从用户信息中获取 daysLived
        if (birthdate) {
            currentDaysLived = moment().diff(moment(birthdate), 'days');
        } else {
            currentDaysLived = 0; // 根据实际情况处理
        }
    } else if (!isAuthenticated && birthdate) {
        // 游客模式下使用存储的 birthdate 计算 daysLived
        currentDaysLived = moment().diff(moment(birthdate), 'days');
    } else {
        currentDaysLived = 0; // 默认值或根据需求处理
    }

    queryParams['lifeDays'] = String(currentDaysLived);
  
    try {
      // 构建查询参数
      const query = new URLSearchParams(queryParams).toString();
      const response = await fetch(`${apiBaseUrl}/api/posts?${query}`, {
        method: 'GET',
        headers,
      });
  
      if (!response.ok) {
        console.error('获取帖子失败:', response.status, response.statusText);
        throw new Error(`Failed to fetch posts: ${response.statusText}`);
      }
  
      const data = await response.json();
  
      // 去重逻辑，确保不会重复添加同一篇帖子
      setPosts(prevPosts => {
        const allPosts = [...prevPosts, ...data];
        return allPosts.filter((post, index, self) => 
          index === self.findIndex((p) => p.id === post.id)
        );
      });
  
      // 每次请求后更新当前的页数
      setPage(page + 1);
    } catch (error) {
      console.error('获取帖子失败:', error);
    }
  }, [page, isAuthenticated, birthdate]);

  useEffect(() => {
    // 每天午夜刷新 daysLived
    const now = moment();
    const nextMidnight = moment().endOf('day').add(1, 'second');
    const msUntilMidnight = nextMidnight.diff(now);

    const timer = setTimeout(() => {
        // 清空已加载的帖子，触发重新获取
        setPosts([]);
        setPage(1);
        fetchPosts();
    }, msUntilMidnight);

    return () => clearTimeout(timer);
}, [fetchPosts]);

  const handleLoadMore = () => {
    fetchPosts();
  };

  const handleEnterTimeline = () => {
    setShowFeed(true)
    if (posts.length === 0) {
      fetchPosts()
    }
  }

  const handleCloseFeed = () => {
    setShowFeed(false)
  }

  const handleVote = async (postId: number, voteType: 'up' | 'down') => {
    if (!isAuthenticated) return;  // 阻止访客投票

    const numericVoteType = voteType === 'up' ? 1 : -1; // 确保 voteType 是数字

    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
      const response = await fetch(`${apiBaseUrl}/api/vote`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ postId, voteType: numericVoteType }), // 发送数字
      });
  
      if (!response.ok) {
        throw new Error('Vote failed');
      }
  
      // 如果投票成功，则更新本地状态
      setPosts(prevPosts => prevPosts.map(post => {
        if (post.id === postId) {
          if (post.userVote === voteType) {
            return {
              ...post,
              userVote: null,
              [voteType === 'up' ? 'upvotes' : 'downvotes']: post[voteType === 'up' ? 'upvotes' : 'downvotes'] - 1,
            };
          } else {
            return {
              ...post,
              userVote: voteType,
              upvotes: voteType === 'up' ? post.upvotes + 1 : (post.userVote === 'up' ? post.upvotes - 1 : post.upvotes),
              downvotes: voteType === 'down' ? post.downvotes + 1 : (post.userVote === 'down' ? post.downvotes - 1 : post.downvotes),
            };
          }
        }
        return post;
      }));
    } catch (error) {
      console.error('Vote failed:', error);
    }
  };

  const handleShareStory = () => {
    setShowPostForm(true);
  };

  const handlePostSubmit = async () => {
    if (postContent.length > 500) {
      setSnackbarMessage(t('postTooLong'));
      setSnackbarOpen(true);
      return;
    }

    if (postsRemaining <= 0) {
      setSnackbarMessage(t('maxPostsReached'));
      setSnackbarOpen(true);
      return;
    }

    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('Token not found');
      }
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL
      const response = await fetch(`${apiBaseUrl}/api/posts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ content: postContent, life_days: daysLived }),
      });

      if (!response.ok) {
        throw new Error('Post failed');
      }

      const newPost = await response.json();
      newPost.User = { username: userInfo?.username };
      setPosts(prevPosts => {
        const allPosts = [newPost, ...prevPosts];
        return allPosts.filter((post, index, self) => 
          index === self.findIndex((p) => p.id === post.id)
        );
      });
      setPostContent('');
      setShowPostForm(false);
      setPostsRemaining(prevPostsRemaining => Math.max(0, prevPostsRemaining - 1));
      setSnackbarMessage(t('postSuccess'));
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Post failed:', error);
      setSnackbarMessage(t('postFailed'));
      setSnackbarOpen(true);
    }
  };

  const handleLogin = () => {
    navigate('/login');
    setAnchorEl(null); 
  };

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleProfileClick = () => {
    navigate('/account');
    setAnchorEl(null); // 关闭菜单
  };

  const handleManagePosts = () => {
    navigate('/manage-posts');
    setAnchorEl(null);
  };

  if (isLoading) {
    return <div>Loading...</div>; // 或者使用一个加载指示器组件
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', bgcolor: 'background.default' }}>
      <AppBar position="fixed" sx={{ width: '100%', bgcolor: 'transparent', boxShadow: 'none' }}>
        <Toolbar>
          <Typography 
            variant="h6" 
            component="div" 
            sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', cursor: 'pointer', color: 'primary.main' }} 
            onClick={() => navigate('/')}
          >
            <TimerIcon sx={{ mr: 1 }} />
            TimeMessager
          </Typography>
          <Button
            color="inherit"
            onClick={(event) => setAnchorEl(event.currentTarget)}
            endIcon={<ArrowDropDown />}
            sx={{
              bgcolor: 'rgba(0, 0, 0, 0.1)', // 添加半透明背景
              '&:hover': {
                bgcolor: 'rgba(0, 0, 0, 0.2)', // 悬停时加深背景
              },
              borderRadius: '20px', // 圆角按钮
              px: 2, // 增加水平内边距
            }}
          >
            <Avatar src={user.avatar} sx={{ width: 32, height: 32, mr: 1 }} />
            <Typography
              sx={{
                color: 'text.primary', // 使用主要文本颜色
                fontWeight: 'bold', // 加粗文字
              }}
            >
              {user.name}
            </Typography>
          </Button>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {!isAuthenticated ? (
              <MenuItem onClick={handleLogin}>{t('login')}</MenuItem>
            ) : (
              <>
                <MenuItem onClick={handleProfileClick}>
                  <Person sx={{ mr: 1 }} />
                  {t('profile')}
                </MenuItem>
                <MenuItem onClick={handleManagePosts}>
                  <Settings sx={{ mr: 1 }} />
                  {t('managePosts')}
                </MenuItem>
                <Divider />
                <MenuItem onClick={handleLogout} sx={{ color: 'error.main' }}>
                  <Logout sx={{ mr: 1 }} />
                  {t('logout')}
                </MenuItem>
              </>
            )}
          </Menu>
        </Toolbar>
      </AppBar>
      <Toolbar />
      <Container maxWidth={false} disableGutters sx={{ mt: 4, mb: 4, flexGrow: 1, display: 'flex', overflow: 'hidden' }}>
        <Grid container spacing={3} sx={{ flexGrow: 1 }}>
          <Grid item xs={12} md={showFeed ? 4 : 12} lg={showFeed ? 3 : 12} sx={{ 
            display: 'flex', 
            justifyContent: 'center', 
            alignItems: 'center', 
            height: { xs: 'auto', md: '100%' } 
          }}>
            <Paper sx={{ 
              p: 3, 
              height: { xs: 'auto', md: '100%' }, 
              width: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              justifyContent: 'center', 
              alignItems: 'center', 
              bgcolor: 'white', 
              borderRadius: '16px', 
              boxShadow: 3 
            }}>
              <Typography variant="h4" gutterBottom>
                {!isAuthenticated ? t('welcomeGuest') : t('welcomeBack', { name: userInfo?.username })}
              </Typography>
              {birthdate && (
                <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                  {t('birthdateInfo')}
                </Typography>
              )}
              <Typography variant="h2" color="primary" gutterBottom>
                {daysLived.toLocaleString()}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary" gutterBottom>
                {t('daysLived')}
              </Typography>
              {!showFeed && (
                <Button
                  variant="contained"
                  size="large"
                  startIcon={<MessageIcon />}
                  onClick={handleEnterTimeline}
                  sx={{ mt: 2 }}
                >
                  {t('meetPeers')}
                </Button>
              )}
              <DaysLivedProgressBar />
            </Paper>
          </Grid>
          {showFeed && (
            <Grid item xs={12} md={8} lg={9}>
              <StyledFeed sx={{
                height: { xs: 'calc(100vh - 64px - 32px)', md: 'calc(100vh - 64px)' },
                overflowY: 'auto',
                mt: { xs: 2, md: 0 },
                bgcolor: 'background.paper',
                borderRadius: '16px',
                boxShadow: 3
              }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                  <Typography variant="h6">{t('feedTitle', { days: daysLived })}</Typography>
                  <Box>
                    <Button
                      variant="contained"
                      startIcon={<AddIcon />}
                      sx={{ mr: 1 }}
                      disabled={!isAuthenticated}
                      onClick={() => !isAuthenticated ? alert(t('guestShareStoryAlert')) : handleShareStory()}
                    >
                      {t('shareStory')}
                    </Button>
                    <IconButton onClick={handleCloseFeed} size="small">
                      <CloseIcon />
                    </IconButton>
                  </Box>
                </Box>
                <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                  {!isAuthenticated 
                    ? t('guestPostsRemaining')
                    : t('postsRemaining', { count: postsRemaining })} {/* 动态显示 */}
                </Typography>
                <AnimatePresence>
                  {showPostForm && (
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      exit={{ opacity: 0, y: -20 }}
                      transition={{ duration: 0.3 }}
                    >
                      <Card sx={{ mb: 2 }}>
                        <CardContent>
                          <TextField
                            fullWidth
                            multiline
                            rows={4}
                            variant="outlined"
                            placeholder={t('shareYourStory')}
                            value={postContent}
                            onChange={(e) => setPostContent(e.target.value)}
                            inputProps={{ maxLength: 500 }}
                          />
                          <Typography variant="caption" color="text.secondary">
                            {`${postContent.length}/500`}
                          </Typography>
                        </CardContent>
                        <CardActions>
                          <Button onClick={handlePostSubmit} variant="contained">
                            {t('post')}
                          </Button>
                          <Button onClick={() => setShowPostForm(false)}>
                            {t('cancel')}
                          </Button>
                        </CardActions>
                      </Card>
                    </motion.div>
                  )}
                </AnimatePresence>
                {posts.length === 0 ? (
                  <Typography variant="body1" color="text.secondary">
                    {t('noPostsAvailable')}
                  </Typography>
                ) : (
                  <List>
                    {posts.map((post) => (
                      <ListItem key={post.id} alignItems="flex-start" sx={{ px: 0 }}>
                        <Card sx={{ width: '100%', mb: 2 }}>
                          <CardHeader
                            avatar={
                              <Avatar src={post.avatar} />
                            }
                            title={post.User ? post.User.username : 'Unkown User'}
                            subheader={new Date(post.created_at).toLocaleDateString()}
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary">
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: post.content.replace(/\n/g, '<br />'),
                                }}
                              />
                            </Typography>
                          </CardContent>
                          <CardActions disableSpacing>
                            <IconButton 
                              onClick={() => !isAuthenticated ? alert(t('guestVoteAlert')) : handleVote(post.id, 'up')} 
                              color={post.userVote === 'up' ? 'primary' : 'default'}
                              disabled={!isAuthenticated}
                            >
                              <ThumbUp />
                            </IconButton>
                            <Typography>{post.upvotes}</Typography>
                            <IconButton 
                              onClick={() => !isAuthenticated ? alert(t('guestVoteAlert')) : handleVote(post.id, 'down')} 
                              color={post.userVote === 'down' ? 'error' : 'default'}
                              disabled={!isAuthenticated}
                            >
                              <ThumbDown />
                            </IconButton>
                            <Typography>{post.downvotes}</Typography>
                          </CardActions>
                        </Card>
                      </ListItem>
                    ))}
                  </List>
                )}
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={handleLoadMore}
                  sx={{ mt: 2 }}
                >
                  {t('loadMore')}
                </Button>
              </StyledFeed>
            </Grid>
          )}
        </Grid>
      </Container>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Box>
  )
}
