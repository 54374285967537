import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage/landingpage';
import LoginPage from './pages/login/LoginPage';
import RegisterPage from './pages/register/registerpage';
import GuestBrowsePage from './pages/guestlogin/GuestBrowsePage';
import Dashboard from './pages/dashboard/Dashboard'; 
import RegisterSuccessPage from './pages/register/SendingVeriEmail'; // 新增导入
import ProfilePage from './pages/account/account'; // 新增导入
import PostManagementPage from './pages/dashboard/PostManagementPage'; // 新增导入
import VerifyEmailPage from './pages/register/VerifyEmailPage'; // 新增导入
import PasswordResetSentPage from './pages/login/PasswordResetSentPage'; // 新增导入
import ResetPasswordPage from './pages/login/ResetPasswordPage'; // 新增导入
import './i18n';
import { AuthProvider } from './context/AuthContext';
import axios from 'axios';
import { SpeedInsights } from '@vercel/speed-insights/react';
import { Helmet } from 'react-helmet';
import { Analytics } from '@vercel/analytics/react';
import { Container } from '@mui/material';
const App: React.FC = () => {
  useEffect(() => {
    const token = localStorage.getItem('token'); // 推荐改为 HttpOnly cookie
    
    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/api/me`, {
          headers: { Authorization: `Bearer ${token}` },
          timeout: 5000, // 设置请求超时时间，防止被阻塞
        })
        .then((response) => {
          // 处理响应
        })
        .catch((error) => {
          console.error('获取用户信息失败:', error);
          // 适当处理错误
        });
    }
  }, []);

  return (
    <>
    <Helmet>
      <title>Time Messager</title> {/* 这里设置网页的标题 */}
    </Helmet>
    <AuthProvider>
      <Container maxWidth="lg">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/guest-browse" element={<GuestBrowsePage />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/sendingVeriEmail" element={<RegisterSuccessPage />} /> {/* 新增路由 */}
          <Route path="/account" element={<ProfilePage />} /> {/* 新增路由 */}
          <Route path="/manage-posts" element={<PostManagementPage />} /> {/* 新增路由 */}
          <Route path="/verify-email" element={<VerifyEmailPage />} /> {/* 新增路由 */}
          <Route path="/password-reset-sent" element={<PasswordResetSentPage />} /> {/* 新增路由 */}
          <Route path="/reset-password" element={<ResetPasswordPage />} /> {/* 新增路由 */}
        </Routes>
        <SpeedInsights />
        <Analytics />
      </Container>
    </AuthProvider>
    </>
  );
};

const AppWrapper: React.FC = () => (
  <Router>
    <AuthProvider>
      <App />
    </AuthProvider>
  </Router>
);

export default AppWrapper;
