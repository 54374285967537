import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import axios from 'axios';
import moment from 'moment'; // Import moment for date calculations

// 定义用户信息的接口
interface UserInfo {
  id: string;
  username: string;
  email: string;
  birthdate: string;
  isVerified: boolean;
}

// 定义认证上下文的类型
interface AuthContextType {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  isGuest: boolean;
  setIsGuest: (value: boolean) => void;
  userInfo: UserInfo | null;
  setUserInfo: (value: UserInfo | null) => void;
  isLoading: boolean;
  birthdate: Date | null;
  setBirthdate: (value: Date | null) => void;
  login: (usernameOrEmail: string, password: string) => Promise<void>;
  logout: () => void;
  browseAsGuest: (lifeDays: number) => void; // 修改参数类型
  forceUpdate: () => void; // 添加 forceUpdate 函数
  canAccessForgotPassword: boolean;
  setCanAccessForgotPassword: (value: boolean) => void;
}

// 创建 AuthContext
export const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [isGuest, setIsGuest] = useState<boolean>(false);
  const [userInfo, setUserInfo] = useState<UserInfo | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [birthdate, setBirthdate] = useState<Date | null>(null);
  const [,setState] = useState<number>(0); // 用于强制更新状态
  const [canAccessForgotPassword, setCanAccessForgotPassword] = useState<boolean>(false);

  // 使用 useEffect 处理自动获取用户信息的逻辑
  useEffect(() => {
    const token = localStorage.getItem('token');
    const abortController = new AbortController(); // 用于取消请求

    if (token) {
      setIsLoading(true);
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/api/me`, {
          headers: { Authorization: `Bearer ${token}` },
          signal: abortController.signal, // 为 axios 提供取消信号
        })
        .then((response) => {
          if (response.status === 200) {
            setUserInfo(response.data);
            setIsAuthenticated(true);
            setIsGuest(false);
            setBirthdate(new Date(response.data.birthdate));
            localStorage.removeItem('guestBirthdate');
          }
        })
        .catch((error) => {
          if (!abortController.signal.aborted) {
            console.error('获取用户信息失败:', error);
            setIsAuthenticated(false);
            setUserInfo(null);
          }
        })
        .finally(() => {
          if (!abortController.signal.aborted) {
            setIsLoading(false);
          }
        });
    } else {
      // 检查是否以游客身份浏览
      const guestBirthdate = localStorage.getItem('guestBirthdate');
      if (guestBirthdate) {
        setIsGuest(true);
        setBirthdate(new Date(guestBirthdate));
      }
      setIsLoading(false);
    }

    return () => {
      abortController.abort(); // 组件卸载时取消请求
    };
  }, []);

  // 登录逻辑，带有错误处理和 token 安全性增强
  const login = async (usernameOrEmail: string, password: string) => {
    try {
      const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
      const response = await fetch(`${apiBaseUrl}/api/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ usernameOrEmail, password }),
      });

      if (!response.ok) {
        const data = await response.json();
        // 如果密码不正确，抛出错误并将用户名和邮箱一同返回
        if (data.message === '密码不正确') {
          throw new Error(JSON.stringify({ 
            message: data.message, 
            username: data.username, 
            email: data.email 
          }));
        } else {
          throw new Error(data.message || '登录失败');
        }
      }
  
      const data = await response.json();
      if (!data.user) {
        throw new Error('API响应中缺少用户数据');
      }
  
      if (!data.user.isVerified) {
        throw new Error('该帐号尚未验证成功');
      }
  

      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user)); // 确保用户信息也存储在本地存储中
      setUserInfo(data.user);
      setIsAuthenticated(true);
      setIsGuest(false);
      setBirthdate(new Date(data.user.birthdate));
      localStorage.removeItem('guestBirthdate');
    } catch (error) {
      throw error;
    }
  };

  // 登出逻辑，封装到一个函数里以避免重复代码
  const logout = () => {
    clearAuthState();
    setIsAuthenticated(false);
    forceUpdate(); // 强制更新状态
  };

  // 用于游客模式浏览
  const browseAsGuest = (lifeDays: number) => {
    const birthdate = moment().subtract(lifeDays, 'days').toDate(); // 计算 birthdate
    localStorage.setItem('guestBirthdate', birthdate.toISOString());
    clearAuthState();
    setIsGuest(true);
    setBirthdate(birthdate); // 设置 birthdate 为 Date 对象
    forceUpdate(); // 强制更新状态
  };

  // 清除认证状态的辅助函数
  const clearAuthState = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('guestBirthdate');
    setIsAuthenticated(false);
    setIsGuest(false);
    setUserInfo(null);
    setBirthdate(null);
  };

  // 强制更新状态的函数
  const forceUpdate = useCallback(() => setState((prev) => prev + 1), []);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        isGuest,
        setIsGuest,
        userInfo,
        setUserInfo,
        isLoading,
        birthdate,
        setBirthdate,
        login,
        logout,
        browseAsGuest, // 提供 browseAsGuest 函数
        forceUpdate, // 提供 forceUpdate 函数
        canAccessForgotPassword,
        setCanAccessForgotPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// 自定义 Hook 用于使用 AuthContext
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
