import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      // 通用翻译
      login: 'Login',
      register: 'Register',
      usernameOrEmail: 'Username or Email',
      username: 'Username',
      email: 'Email',
      password: 'Password',
      confirmPassword: 'Confirm Password',
      birthdate: 'Birthdate',
      agreeTerms: 'I have read and agree to the',
      privacyPolicy: 'Privacy Policy',
      termsOfService: 'Terms of Service',
      and: 'and',
      backToHome: 'Back to Home',
      noAccount: "Don't have an account? Sign Up",
      alreadyHaveAccount: 'Already have an account? Log in',
      getStarted: 'Get Started',
      slogan: 'Meet peers from different times and spaces',
      description: 'This is a time-space social app\nwhere only those destined to cross your path will encounter your joys and sorrows.',
      birthdateWarning: 'Birthdate cannot be changed after registration',
      viewFeed: 'Back to time travel',
      // 登录页面错误提示
      loginError: {
        emptyFields: 'Please fill in all fields',
        invalid: 'Invalid username or password',
        userNotFound: 'User not found.',
        incorrectPassword: 'Incorrect password.',
        failed: 'Login failed. Please check your username and password.',
        unknown: 'An unknown error occurred.',
        forgotPassword: 'Forgot password?',
        tooManyRequests: 'Too many requests. Please try again in',
        minutes: 'minutes',
        laterTryAgain: 'later.',
        accountNotVerified: 'Account not verified.',
        invalidCredentials: 'Invalid username or password.',
      },
      // 注册页面错误提示
      registerError: {
        usernameRequired: 'Username is required',
        emailRequired: 'Email is required',
        invalidEmail: 'Please enter a valid email',
        passwordRequired: 'Password is required',
        passwordMismatch: 'Passwords do not match',
        birthdateRequired: 'Please select your birthdate',
        agreeTermsRequired: 'You must agree to the terms',
      },
      guestBrowse: 'Guest Browse',
      startBrowsing: 'Start Browsing',
      guestBrowseWarning: 'You will browse as a guest and cannot post content.',
      guestBrowseError: {
        birthdateRequired: 'Please select your birthdate',
        futureBirthdate: 'Birthdate cannot be in the future',
      },
      createAccount: 'Create an Account',
      // 新增翻译
      profile: 'Profile',
      managePosts: 'Manage Posts',
      deleteAccount: 'Delete Account',
      logout: 'Logout',
      birthdateInfo: 'Today is the',
      daysLived: 'day of your life!',
      meetPeers: 'Meet Peers in the Timeline',
      welcomeBack: 'Welcome back, {{name}}!',
      enterTimeline: 'Enter My Timeline',
      // Feed 页面翻译
      newPost: 'New Post',
      postPlaceholder: 'What\'s on your mind?',
      post: 'Post',
      cancel: 'Cancel',
      guestPostError: 'Guest users cannot create new posts. Please log in.',
      maxPostsReached: 'You have reached your daily post limit.',
      guestVoteError: 'Guest users cannot vote. Please log in.',
      loadMore: 'Load More',
      noMorePosts: 'No more posts to load',
      shareStory: 'Share Story',
      welcomeGuest: 'Welcome Guest!',
      loginSuccess: 'Login successful!',
      feedTitle: 'All posts here are left by people on their {{days}}th day of life',
      shareYourStory: 'Share your current mental state and story with people from different times and spaces!',
      postsRemaining: 'You can still post {{count}} times today',
      guestPostsRemaining: 'Guest users cannot post. Please log in.',
      // 新增的提醒内容
      registerSuccess: 'Registration successful!',
      usernameExists: 'Username already exists',
      emailExists: 'Email already exists',
      invalidCredentials: 'Invalid username or password',
      serverError: 'Server error, please try again later',
      postTooLong: 'Post content is too long',
      postSuccess: 'Post published successfully!',
      postFailed: 'Failed to publish post',
      noPostsAvailable: 'No posts available. Be the first to post something!',
      backToDashboard: "Back to Dashboard",
      confirmDeleteAccount: "Confirm Account Deletion",
      deleteAccountWarning: "Are you sure you want to delete your account?",
      deleteAccountDataLoss: "This action will permanently delete all your posts and related data. This cannot be undone.",
      confirm: "Confirm",
      searchPosts: 'Search posts',
      filterByMonth: 'Filter by month',
      deleteSelectedPosts: 'Delete Selected Posts',
      content: 'Content',
      createdAt: 'Created At',
      upvotes: 'Upvotes',
      downvotes: 'Downvotes',
      userVote: 'User Vote',
      noVote: 'No Vote',
      forgotPassword: 'Forgot password?',
      forgotPasswordError: {
        emptyEmail: "Email is required.",
        emailSendFailed: "Failed to send reset email.",
        unknown: "An unknown error occurred.",
        invalidToken: "Invalid or expired token.",
        verificationFailed: "Token verification failed.",
        missingToken: "Missing token.",
        passwordMismatch: "Passwords do not match.",
        resetFailed: "Failed to reset password.",
      },
      forgotPasswordEmailSent: "A password reset email has been sent.",
      resetPassword: "Reset Password",
      sendResetEmail: "Send Reset Email",
      backToLogin: "Back to Login",
      forgotPasswordSuccess: "Your password has been reset successfully.",
      lifeTimeline: 'Timeline: Moments where lives intersect',
      fetchError: 'Failed to fetch DaysLived data:',
      day: 'Day {{day}}',
      postCount: 'Total {{count}} posts',
      lastPostTime: 'Last post time: {{time}}',
      appName: 'TimeMassager',
      copyright: '© {{year}} TimeMassager',
      // 新增的翻译
      ResetPassword: {
        title: 'Reset Password',
        newPassword: 'New Password',
        confirmNewPassword: 'Confirm New Password',
        submit: 'Submit',
        submitting: 'Submitting...',
        passwordsDoNotMatch: 'Passwords do not match',
        success: 'Password reset successful',
        failed: 'Failed to reset password',
      },
      sendingVeriEmail: {
        registrationSuccess: 'Registration Successful!',
        emailSentMessage: 'Congratulations on completing your registration! We have sent a verification email to your inbox.',
          clickToSendEmail: 'Congratulations on completing your registration! Click the button below to send a verification email to your inbox.',
          checkEmailInstructions: 'Please check your email and click the verification link to complete the final step.',
          checkSpamFolder: "Haven't received the email? Please check your spam folder.",
          resendEmailButton: 'Resend Verification Email ({{time}})',
          resendEmailButtonDefault: 'Resend Verification Email',
          resendCooldown: 'You can resend the verification email after 120 seconds.',
          resendSuccess: 'Verification email has been resent. Please check your inbox. You can resend again after 120 seconds.',
        resendFailed: 'Failed to resend verification email. Please try again later.',
        backToHome: 'Back to Home',
        localStorageCleared: 'localStorage has been cleared',
      },
      verifyEmail: {
        success: 'Email verification successful!',
        failed: 'Email verification failed.',
      },
      PasswordResetSent: {
        title: 'Password Reset Email Sent',
        message: 'We have sent a password reset email to your email address',
        instruction: 'Please click the link in the email to reset your password. The link is valid for 1 hour and will expire after that.',
        countdown: 'The page will automatically redirect to the homepage in',
        seconds: 'seconds',
        backToHome: 'Back to Homepage'
      },
      setResetPassword: {
        title: 'Reset Password',
        success: 'Password reset successful',
        failed: 'Failed to reset password',
        newPassword: 'New Password',
        confirmNewPassword: 'Confirm New Password',
        submit: 'Submit',
        submitting: 'Submitting...',
        passwordsDoNotMatch: 'Passwords do not match',
      },
    },
  },
  zh: {
    translation: {
      // 通用翻译
      login: '登录',
      register: '注册',
      usernameOrEmail: '用户名或邮箱',
      username: '用户名',
      email: '邮箱',
      password: '密码',
      confirmPassword: '确认密码',
      birthdate: '出生日期',
      agreeTerms: '我已阅读并同意',
      privacyPolicy: '隐私政策',
      termsOfService: '服务条款',
      and: '和',
      backToHome: '返回首页',
      noAccount: '没有账户？立即注册',
      alreadyHaveAccount: '已有账户？登录',
      getStarted: '立即开始',
      slogan: '遇见不同时空的同龄人',
      description: '这是一款时空社交APP, 你的喜怒哀乐只会被有缘人遇见',
      birthdateWarning: '注册后，出生日期不可修改',
      viewFeed: '回到时空穿梭中',
     // 登录页面错误提示
     loginError: {
      emptyFields: '请填写所有字段',
      invalid: '用户名或密码无效',
      userNotFound: '用户不存在。',
      incorrectPassword: '密码不正确。',
      failed: '登录失败。请检查您的用户名和密码。',
      unknown: '发生未知错误。',
      forgotPassword: '忘记密码？',
      tooManyRequests: '请求过多。请在',
      minutes: '分钟后',
      laterTryAgain: '再试。',
      accountNotVerified: '账户未验证。',
      invalidCredentials: '用户名或密码无效。',
    },
      // 注册页面错误提示
      registerError: {
        usernameRequired: '用户名为必填项',
        emailRequired: '邮箱为必填项',
        invalidEmail: '请输入有效的邮箱',
        passwordRequired: '密码为必填项',
        passwordMismatch: '密码不匹配',
        birthdateRequired: '请选择您的出生日期',
        agreeTermsRequired: '您必须同意条款',
      },
      guestBrowse: '游客浏览',
      startBrowsing: '开始浏览',
      guestBrowseWarning: '您将以游客身份浏览，无法发布内容。',
      guestBrowseError: {
        birthdateRequired: '请选择您的出生日期',
        futureBirthdate: '出生日期不能是将来的日期',
      },
      createAccount: '创建账户',
      // 新增翻译
      profile: '个人资料',
      managePosts: '帖子管理',
      deleteAccount: '注销账户',
      logout: '退出登录',
      birthdateInfo: '今天是你生命的第',
      daysLived: '天！',
      meetPeers: '遇见时光里的同龄人',
      welcomeBack: '欢迎回来，{{name}}!',
      enterTimeline: '进入我的时空',
      // Feed 页面翻译
      newPost: '发布新帖',
      postPlaceholder: '有什么想法？',
      post: '发布',
      cancel: '取消',
      guestPostError: '访客用户无法发布新帖子，请先登录。',
      maxPostsReached: '您今天已达到发帖上限。',
      guestVoteError: '访客用户无法进行投票，请先登录。',
      loadMore: '加载更多',
      noMorePosts: '没有更多帖子了',
      shareStory: '分享故事',
      welcomeGuest: '欢迎，访客！',
      loginSuccess: '登录成功！',
      feedTitle: '现在展示的所有分享,都是人们在他们生命第{{days}}天留下的',
      postsRemaining: '您今天还可以发布{{count}}次帖子',
      guestPostsRemaining: '游客不支持发布帖子, 请登录后发布。',
      shareYourStory: '与来自不同时空的人们分享你当下的精神状态和故事吧！',
      // 新增的提醒内容
      registerSuccess: '注册成功！',
      usernameExists: '用户名已存在',
      emailExists: '邮箱已存在',
      invalidCredentials: '用户名或密码无效',
      serverError: '服务器错误，请稍后再试',
      postTooLong: '帖子内容过长',
      postSuccess: '帖子发布成功！',
      postFailed: '发布帖子失败',
      noPostsAvailable: '没有帖子。成为第一个发布内容的人吧！',
      backToDashboard: "返回仪表板",
      confirmDeleteAccount: "确认删除账户",
      deleteAccountWarning: "您确定要删除您的账户吗？",
      deleteAccountDataLoss: "此操作将永久删除您的所有帖子和相关数据。此操作无法撤销。",
      confirm: "确认",
      searchPosts: '搜索帖子',
      filterByMonth: '按月份筛选',
      deleteSelectedPosts: '删除选中的帖子',
      content: '内容',
      createdAt: '创建时间',
      upvotes: '赞',
      downvotes: '踩',
      userVote: '用户投票',
      noVote: '无投票',
      forgotPassword: '忘记密码？',
      forgotPasswordError: {
        emptyEmail: "邮箱不能为空。",
        emailSendFailed: "发送重置邮件失败。",
        unknown: "发生未知错误。",
        invalidToken: "无效或已过期的令牌。",
        verificationFailed: "令牌验证失败。",
        missingToken: "缺少令牌。",
        passwordMismatch: "密码不匹配。",
        resetFailed: "重置密码失败。",
      },
      forgotPasswordEmailSent: "密码重置邮件已发送。",
      resetPassword: "重置密码",
      sendResetEmail: "发送重置邮件",
      backToLogin: "返回登录",
      forgotPasswordSuccess: "您的密码已成功重置。",
      lifeTimeline: '时间轴：那些人生交汇的时刻',
      fetchError: '获取 DaysLived 数据失败:',
      day: '第 {{day}} 天',
      postCount: '共有 {{count}} 个帖子',
      lastPostTime: '最后发帖时间: {{time}}',
      appName: 'TimeMassager',
      copyright: '© {{year}} TimeMassager',
      // 新增的翻译
      ResetPassword: {
        title: '重置密码',
        newPassword: '新密码',
        confirmNewPassword: '确认新密码',
        submit: '提交',
        submitting: '提交中...',
        passwordsDoNotMatch: '密码不匹配',
        success: '密码重置成功',
        failed: '密码重置失败',
      },
      sendingVeriEmail: {
        registrationSuccess: '注册成功！',
        emailSentMessage: '恭喜您完成注册！我们已经向您的邮箱发送了一封验证邮件。',
        clickToSendEmail: '恭喜您完成注册！点击下面按钮向您的邮箱发送一封验证邮件。',
        checkEmailInstructions: '请查看您的邮箱并点击验证链接以完成最后一步。',
        checkSpamFolder: '没有收到邮件？请检查您的垃圾文件夹。',
        resendEmailButton: '重新发送验证邮件 ({{time}})',
        resendEmailButtonDefault: '重新发送验证邮件',
        resendCooldown: '120秒后才可再次点击重新发送验证邮件。',
        resendSuccess: '验证邮件已重新发送，请检查您的邮箱。120秒后才可再次点击重新发送验证邮件。',
        resendFailed: '重新发送验证邮件失败，请稍后再试。',
        backToHome: '返回首页',
        localStorageCleared: 'localStorage 已清空',
      },
      PasswordResetSent: {
        title: '重置密码邮件已发送',
        message: '我们已经向您的邮箱发送了一封重置密码的邮件',
        instruction: '请点击邮件中的链接以重置您的密码。该链接有效期为1小时，过期后将无法使用。',
        countdown: '页面将在',
        seconds: '秒后自动跳转回首页',
        backToHome: '返回首页'
      },
      verifyEmail: {
        success: '邮箱验证成功！',
        failed: '邮箱验证失败。',
      },
      setResetPassword: {
        title: '重置密码',
        success: '密码重置成功',
        failed: '密码重置失败',
        newPassword: '新密码',
        confirmNewPassword: '确认新密码',
        submit: '提交',
        submitting: '提交中...',
        passwordsDoNotMatch: '密码不匹配',
      },
    },
  },
  ko: {
    translation: {
      // 통용 번역
      login: '로그인',
      register: '회원가입',
      usernameOrEmail: '사용자 이름 또는 이메일',
      username: '사용자 이름',
      email: '이메일',
      password: '비밀번호',
      confirmPassword: '비밀번호 확인',
      birthdate: '생년월일',
      agreeTerms: '저는',
      privacyPolicy: '개인정보 처리방침',
      termsOfService: '이용 약관',
      and: '및',
      backToHome: '홈으로 돌아가기',
      noAccount: '계정이 없으신가요? 가입하기',
      alreadyHaveAccount: '이미 계정이 있으신가요? 로그인하기',
      getStarted: '시작하기',
      slogan: '다른 시공간의 또래를 만나다',
      description: '이것은 시공간을 넘나드는 소셜 앱입니다. \n당신의 기쁨과 슬픔은 오직 운명적으로 만난 사람들에게만 전해집니다.',
      birthdateWarning: '생년월일은 등  변경할 수 없습니다',
      viewFeed: '시간 여행으로 돌아가기', 
      // 로그인 오류 메시지
      loginError: {
        emptyFields: '모든 필드를 입력해주세요',
        invalid: '잘못된 사용자 이름 또는 비밀번호입니다',
        userNotFound: '사용자를 찾을 수 없습니다.',
        incorrectPassword: '비밀번호가 틀렸습니다.',
        failed: '로그인 실패. 사용자 이름과 비밀번호를 확인하세요.',
        unknown: '알 수 없는 오류가 발생했습니다.',
        forgotPassword: '비밀번호를 잊으셨나요?',
        tooManyRequests: '요청이 너무 많습니다. 다시 시도해 주세요',
        minutes: '분 후에',
        laterTryAgain: '다시 시도해 주세요.',
        accountNotVerified: '계정이 확인되지 않았습니다.',
        invalidCredentials: '잘못된 사용자 이름 또는 비밀번호입니다.',
      },
      // 회원가입 오류 메시지
      registerError: {
        usernameRequired: '사용자 이름은 필수 항목입니다',
        emailRequired: '이메일은 필수 항목입니다',
        invalidEmail: '유효한 이메일을 입력하세요',
        passwordRequired: '비밀번호는 필수 항목입니다',
        passwordMismatch: '비밀번호가 일치하지 않습니다',
        birthdateRequired: '생년월일을 선택하세요',
        agreeTermsRequired: '약관에 동의해야 합니다',
      },
      guestBrowse: '게스트 브라우즈',
      startBrowsing: '둘러보기 시작',
      guestBrowseWarning: '게스트로 둘러보며, 게시물을 작성할 수 없습니다.',
      guestBrowseError: {
        birthdateRequired: '생년월일을 선택하세요',
        futureBirthdate: '생년월일은 미래일 수 없습니다',
      },
      createAccount: '계정 만들기',
      // 新增翻译
      profile: '프로필',
      managePosts: '게시물 관리',
      deleteAccount: '계정 삭제',
      logout: '로그아웃',
      birthdateInfo: '오늘은 당신의 삶의',
      daysLived: '번째 날입니다!',
      meetPeers: '타임라인서 또래를 만나보세요',
      welcomeBack: '다시 오신 것을 환영합니다, {{name}}!',
      enterTimeline: '내 타임라인으로 들어가기',
      // Feed 페이지 번역
      newPost: '새 게시물',
      postPlaceholder: '무슨 생각을 하고 계신가요?',
      post: '게시',
      cancel: '취소',
      guestPostError: '게스트 사용자는 새 게시물을 작성할 수 없습니다. 로그인해 주세요.',
      maxPostsReached: '오늘의 게시물 한도에 도달했습니다.',
      guestVoteError: '게스트 사용자는 투표할 수 없습니다. 로그인해 주세요.',
      loadMore: '더 보기',
      noMorePosts: '더 이상 불러올 게시물이 없습니다',
      shareStory: '이야기 공유',
      welcomeGuest: '환영합니다, 게스트님!',
      loginSuccess: '로그인 성공!',
      feedTitle: '이 곳의 모든 공유는 각자의 생애에서 {{days}}번째 날에 남긴 것입니다',
      postsRemaining: '오늘 {{count}}번 더 게시물을 올릴 수 있습니다',
      guestPostsRemaining: '게스트 사용자는 게시물을 작성할 수 없습니다. 로그인해 주세요.',
      shareYourStory: '당신의 현재 상태와 이야기를 다른 시간과 공간의 사람들과 공유하세요!',
      // 新增的提醒内容
      registerSuccess: '회원가입 성공!',
      usernameExists: '이미 존재하는 사용자 이름입니다',
      emailExists: '이미 존재하는 이메일입니다',
      invalidCredentials: '잘못된 사용자 이름 또는 비밀번호입니다',
      serverError: '서버 오류, 나중에 다시 시도해주세요',
      postTooLong: '게시물 내용이 너무 깁니다',
      postSuccess: '게시물이 성공적으로 게시되었습니다!',
      postFailed: '게시물 게시 실패',
      noPostsAvailable: '게시물이 없습니다. 첫 번째로 게시물을 작성해보세요!',
      backToDashboard: "대시보드로 돌아가기",
      confirmDeleteAccount: "계정 삭제 확인",
      deleteAccountWarning: "정말로 계정을 삭제하시겠습니까?",
      deleteAccountDataLoss: "이 작업은 모든 게시물과 관련 데이터를 영구적으로 삭제합니다. 이 작업은 취소할 수 없습니다.",
      confirm: "확인",
      searchPosts: '검색 게시물',
      filterByMonth: '월별 필터',
      deleteSelectedPosts: '선택한 게시물 삭제',
      content: '내용',
      createdAt: '작성일',
      upvotes: '추천',
      downvotes: '비추천',
      userVote: '사용자 투표',
      noVote: '투표 없음',
      forgotPassword: '비밀번호를 잊으셨나요?',
      forgotPasswordError: {
        emptyEmail: "이메일은 필수 항목입니다.",
        emailSendFailed: "비밀번호 재설정 이메일 발송에 실패했습니다.",
        unknown: "알 수 없는 오류가 발생했습니다.",
        invalidToken: "유효하지 않거나 만료된 토큰입니다.",
        verificationFailed: "토큰 확인에 실패했습니다.",
        missingToken: "토큰이 누락되었습니다.",
        passwordMismatch: "비밀번호가 일치하지 않습니다.",
        resetFailed: "비밀번호 재설정에 실패했습니다.",
      },
      forgotPasswordEmailSent: "비밀번호 재설정 이메일이 발송되었습니다.",
      resetPassword: "비밀번호 재설정",
      sendResetEmail: "재설정 이메일 발송",
      backToLogin: "로그인으로 돌아가기",
      forgotPasswordSuccess: "비밀번호가 성공적으로 재설정되었습니다.",
      lifeTimeline: '타임라인: 인생이 교차하는 순간들',
      fetchError: 'DaysLived 데이터를 가져오지 못했습니다:',
      day: '제 {{day}} 일',
      postCount: '총 {{count}} 게시물',
      lastPostTime: '마지막 게시 시간: {{time}}',
      appName: 'TimeMassager',
      copyright: '© {{year}} TimeMassager',
      // 新增的翻译
      ResetPassword: {
        title: '비밀번호 재설정',
        newPassword: '새 비밀번호',
        confirmNewPassword: '새 비밀번호 확인',
        submit: '제출',
        submitting: '제출 중...',
        passwordsDoNotMatch: '비밀번호가 일치하지 않습니다',
        success: '비밀번호 재설정 성공',
        failed: '비밀번호 재설정 실패',
      },
      sendingVeriEmail: {
        registrationSuccess: '회원가입 성공!',
        emailSentMessage: '회원가입 축하드립니다! 이메일 인증 메일을 보냈습니다.',
        clickToSendEmail: '회원가입 축하드립니다! 아래 버튼을 클릭하여 이메일 인증 메일을 보낼 수 있습니다.',
        checkEmailInstructions: '이메일을 확인하고 마지막 단계를 완료하세요.',
        checkSpamFolder: '이메일이 도착하지 않았나요? 스팸 폴더를 확인해보세요.',
        resendEmailButton: '인증 메일 재전송 ({{time}})',
        resendEmailButtonDefault: '인증 메일 재전송', 
        resendCooldown: '120초 후에 다시 인증 메일을 재전송할 수 있습니다.',
        resendSuccess: '인증 메일이 재전송되었습니다. 이메일을 확인하세요. 120초 후에 다시 인증 메일을 재전송할 수 있습니다.',
        resendFailed: '인증 메일 재전송 실패, 잠시 후 다시 시도해주세요.',
        backToHome: '홈으로 돌아가기',
        localStorageCleared: 'localStorage 초기화됨',
      },
      PasswordResetSent: {
        title: '비밀번호 재설정 이메일 발송됨',
        message: '귀하의 이메일 주소로 비밀번호 재설정 이메일을 보냈습니다',
        instruction: '이메일에 있는 링크를 클릭하여 비밀번호를 재설정하세요. 링크는 1시간 동안 유효하며 그 후에는 만료됩니다.',
        countdown: '페이지가 자동으로 홈페이지로 리다이렉트됩니다',
        seconds: '초 후',
        backToHome: '홈페이지로 돌아가기'
      },
      verifyEmail: {
        success: '이메일 인증 성공!',
        failed: '이메일 인증 실패.',
      },
      setResetPassword: {
        title: '비밀번호 재설정',
        success: '비밀번호 재설정 성공',
        passwordsDoNotMatch: '비밀번호가 일치하지 않습니다',
        failed: '비밀번호 재설정 실패',
        submit: '제출',
        submitting: '제출 중...',
        newPassword: '새 비밀번호',
        confirmNewPassword: '새 비밀번호 확인',
      },
    },
  },
  ja: {
    translation: {
      // 一般的な翻訳
      login: 'ログイン',
      register: '登録',
      usernameOrEmail: 'ユーザー名またはメールアドレス',
      username: 'ユーザー名',
      email: 'メールアドレス',
      password: 'パスワード',
      confirmPassword: 'パスワードを確認',
      birthdate: '生年月日',
      agreeTerms: '私は',
      privacyPolicy: 'プライバシーポリシー',
      termsOfService: '利用規約',
      and: 'および',
      backToHome: 'ホームに戻る',
      noAccount: 'アカウントをお持ちではないですか？ サインアップ',
      alreadyHaveAccount: 'すでにアカウントをお持ちですか？ ログイン',
      getStarted: '始めましょう',
      slogan: '異なる時空の同世代と出会う',
      description: 'これは時空を超えたソーシャルアプリです。\nあなたの喜怒哀楽は、縁ある者だけが出会うことができます。',
      birthdateWarning: '生年月日は登録後に変更できません',
      viewFeed: '時空を超えて戻る', 
      // ログインページエラーメッセージ
      loginError: {
        emptyFields: 'すべてのフィールドに入力してください',
        invalid: '無効なユーザー名またはパスワードです',
        userNotFound: 'ユーザーが見つかりません。',
        incorrectPassword: 'パスワードが間違っています。',
        failed: 'ログインに失敗しました。ユーザー名とパスワードを確認してください。',
        unknown: '不明なエラーが発生しました。',
        forgotPassword: 'パスワードを忘れた？',
        tooManyRequests: 'リクエストが多すぎます。もう一度試してください',
        minutes: '分後に',
        laterTryAgain: 'もう一度試してください。',
        accountNotVerified: 'アカウントが確認されていません。',
        invalidCredentials: '無効なユーザー名またはパスワードです。',
      },
      // 登録ページエラーメッセージ
      registerError: {
        usernameRequired: 'ユーザー名は必須です',
        emailRequired: 'メールアドレスは必須です',
        invalidEmail: '有効なメールアドレスを入力してください',
        passwordRequired: 'パスワードは必須です',
        passwordMismatch: 'パスワードが一致しません',
        birthdateRequired: '生年月日を選択してください',
        agreeTermsRequired: '利用規約に同意する必要があります',
      },
      guestBrowse: 'ゲストブラウズ',
      startBrowsing: 'ブラウズを開始',
      guestBrowseWarning: 'ゲストとしてブラウズし、投稿はできません。',
      guestBrowseError: {
        birthdateRequired: '生年月日を選択してください',
        futureBirthdate: '生年月日は将来の日付にはできません',
      },
      createAccount: 'アカウントを作成',
      // 新增翻译
      profile: 'プロフィール',
      managePosts: '投稿管理',
      deleteAccount: 'アカウントを削除',
      logout: 'ログアウト',
      birthdateInfo: '今日はあなたの人生の第',
      daysLived: '日目です！',
      meetPeers: 'タイムラインで同世代と出会う',
      welcomeBack: 'お帰りなさい、{{name}}さん！',
      enterTimeline: '私のタイムラインに入る',
      // フィードページの翻訳
      newPost: '新規投稿',
      postPlaceholder: '何を考えていますか？',
      post: '投稿',
      cancel: 'キャンセル',
      guestPostError: 'ゲストユーザーは新しい投稿を作成できません。ログインしてください。',
      maxPostsReached: '本日の投稿上限に達しました。',
      guestVoteError: 'ゲストユーザーは投  できません。ログインしてください。',
      loadMore: 'もっと見る',
      noMorePosts: 'これ以上の投稿はありません',
      shareStory: '物語を共有',
      welcomeGuest: 'ゲストさん、ようこそ！',
      loginSuccess: 'ログインに成功しました！',
      feedTitle: '現在展示のすべてのシェアは、人々が彼らの人生の第{{days}}日に残したものです',
      postsRemaining: 'あなたは今日{{count}}回投稿することができます',
      guestPostsRemaining: 'ゲストユーザーは投稿をすることができません。ログインしてください。',
      shareYourStory: 'あなたの現在の精神状態と物語を、異なる時間と空間の人々と共有してください！',
      // 新增的提醒内容
      registerSuccess: '登録に成功しました！',
      usernameExists: 'ユーザー名はすでに存在します',
      emailExists: 'メールアドレスはすでに存在します',
      invalidCredentials: '無効なユーザー名またはパスワードです',
      serverError: 'サーバーエラー、後でもう一度お試しください',
      postTooLong: '投稿内容が長すぎます',
      postSuccess: '投稿が成功しました！',
      postFailed: '投稿に失敗しました',
      noPostsAvailable: '投稿がありません。最初に投稿してみましょう！',
      backToDashboard: "ダッシュボードに戻る",
      confirmDeleteAccount: "アカウント削除の確認",
      deleteAccountWarning: "本当にアカウントを削除しますか？",
      deleteAccountDataLoss: "この操作により、すべての投稿と関連データが永久に削除されます。この操作は取り消すことができません。",
      confirm: "確認",
      searchPosts: '投稿を検索',
      filterByMonth: '月別にフィルター',
      deleteSelectedPosts: '選択した投稿を削除',
      content: '内容',
      createdAt: '作成日',
      upvotes: 'いいね',
      downvotes: 'いいね解除',
      userVote: 'ユーザーの投票',
      noVote: '投票なし',
      forgotPassword: 'パスワードを忘れた？',
      forgotPasswordError: {
        emptyEmail: "メールアドレスは必須です。",
        emailSendFailed: "パスワードリセットメールの送信に失敗しました。",
        unknown: "不明なエラーが発生しました。",
        invalidToken: "無効または期限切れのトークンす。",
        verificationFailed: "トークンの検証に失敗しました。",
        missingToken: "トークンがありません。",
        passwordMismatch: "パスワードが一致しません。",
        resetFailed: "パスワードのリセットに失敗しました。",
      },
      forgotPasswordEmailSent: "パスワードリセットメールを送信しました。",
      resetPassword: "パスワードをリセット",
      sendResetEmail: "リセットメールを送信",
      backToLogin: "ログインに戻る",
      forgotPasswordSuccess: "パスワードが正常にリセットされました。",
      lifeTimeline: 'タイムライン：人生が交差するあの瞬間',
      fetchError: 'DaysLived データの取得に失敗しました:',
      day: '第 {{day}} 日',
      postCount: '合計 {{count}} 投稿',
      lastPostTime: '最後の投稿時間: {{time}}',
      appName: 'TimeMassager',
      copyright: '© {{year}} TimeMassager',
      // 新增的翻译
      ResetPassword: {
        title: 'パスワードをリセット',
        newPassword: '新しいパスワード',
        confirmNewPassword: '新しいパスワードを確認',
        submit: '送信',
        submitting: '送信中...',
        passwordsDoNotMatch: 'パスワードが一致しません',
        success: 'パスワードのリセットに成功しました',
        failed: 'パスワードのリセットに失敗しました',
      },
      sendingVeriEmail: {
        registrationSuccess: '登録に成功しました！',
        emailSentMessage: '登録おめでとうございます！メールの認証を送信しました。',
        clickToSendEmail: '登録おめでとうございます！下のボタンをクリックしてメールの認証を送信できます。',
        checkEmailInstructions: 'メールを確認して、最後のステップを完了してください。',
        checkSpamFolder: 'メールが届かない？迷惑メールフォルダを確認してください。',
        resendEmailButton: '認証メールの再送信 ({{time}})',
        resendEmailButtonDefault: '認証メールの再送信',
        resendCooldown: '120秒後に再送信できます。',
        resendSuccess: '認証メールが再送信されました。メールを確認してください。120秒後に再送信できます。',
        resendFailed: '認証メールの再送信に失敗しました。後でもう一度お試しください。',
        backToHome: 'ホームに戻る',
        localStorageCleared: 'localStorage が初期化されました',
      },  
      PasswordResetSent: {
        title: 'パスワードリセットメールが送信されました',
        message: 'パスワードリセットメールをあなたのメールアドレスに送信しました',
        instruction: 'メールに記載されているリンクをクリックしてパスワードをリセットしてください。リンクは1時間有効で、その後は期限切れとなります。',
        countdown: 'ページは自動的にホームページにリダイレクトされます',
        seconds: '秒後',
        backToHome: 'ホームページに戻る'
      },
      verifyEmail: {
        success: 'メールアドレスの認証に成功しました！',
        failed: 'メールアドレスの認証に失敗しました。',
      },
      setResetPassword: {
        title: 'パスワードをリセット',
        success: 'パスワードのリセットに成功しました',
        failed: 'パスワードのリセットに失敗しました',
        passwordsDoNotMatch: 'パスワードが一致しません',
        newPassword: '新しいパスワード',
        confirmNewPassword: '新しいパスワードを確認',
        submit: '送信',
        submitting: '送信中...',
      },
    },
  },
  es: {
    translation: {
      // Traducciones generales
      login: 'Iniciar sesión',
      register: 'Registrarse',
      usernameOrEmail: 'Nombre de usuario o correo electrónico',
      username: 'Nombre de usuario',
      email: 'Correo electrónico',
      password: 'Contraseña',
      confirmPassword: 'Confirmar contraseña',
      birthdate: 'Fecha de nacimiento',
      agreeTerms: 'He leído y estoy de acuerdo con la',
      privacyPolicy: 'Política de privacidad',
      termsOfService: 'Términos de servicio',
      and: 'y',
      backToHome: 'Volver al inicio',
      noAccount: '¿No tienes una cuenta? Regístrate',
      alreadyHaveAccount: '¿Ya tienes una cuenta? Iniciar sesión',
      getStarted: 'Comenzar',
      slogan: 'Conoce a tus pares de diferentes tiempos y espacios',
      description: 'Esta es una aplicación social de tiempo y espacio, \ndonde solo aquellos destinados a encontrarte experimentarán tus alegrías y tristezas.',
      birthdateWarning: 'La fecha de nacimiento no se puede cambiar después del registro',
      viewFeed: 'De vuelta al viaje en el tiempo',
      // Mensajes de error de inicio de sesión
      loginError: {
        emptyFields: 'Por favor, rellena todos los campos',
        invalid: 'Nombre de usuario o contraseña incorrectos',
        userNotFound: 'Usuario no encontrado.',
        incorrectPassword: 'Contraseña incorrecta.',
        failed: 'Error de inicio de sesión. Por favor, verifique su nombre de usuario y contraseña.',
        unknown: 'Ocurrió un error desconocido.',
        forgotPassword: '¿Olvidaste tu contraseña?',
        tooManyRequests: 'Demasiadas solicitudes. Por favor, inténtelo de nuevo en',
        minutes: 'minutos',
        laterTryAgain: 'más tarde.',
        accountNotVerified: 'La cuenta no está verificada.',
        invalidCredentials: 'Nombre de usuario o contraseña inválidos.',
      },
      // Mensajes de error de registro
      registerError: {
        usernameRequired: 'El nombre de usuario es obligatorio',
        emailRequired: 'El correo electrónico es obligatorio',
        invalidEmail: 'Por favor, introduce un correo electrónico válido',
        passwordRequired: 'La contraseña es obligatoria',
        passwordMismatch: 'Las contraseñas no coinciden',
        birthdateRequired: 'Por favor, selecciona tu fecha de nacimiento',
        agreeTermsRequired: 'Debes aceptar los términos',
      },
      guestBrowse: 'Búsqueda como invitado',
      startBrowsing: 'Comienza a buscar',
      guestBrowseWarning: 'Estás navegando como invitado y no puedes publicar contenido.',
      guestBrowseError: {
        birthdateRequired: 'Por favor, selecciona tu fecha de nacimiento',
        futureBirthdate: 'La fecha de nacimiento no puede ser en el futuro',
      },
      createAccount: 'Crear una cuenta',
      // 新增翻译
      profile: 'Perfil',
      managePosts: 'Gestionar Publicaciones',
      deleteAccount: 'Eliminar Cuenta',
      logout: 'Cerrar sesión',
      birthdateInfo: 'Hoy es el día número',
      daysLived: 'de tu vida!',
      meetPeers: 'Conoce a tus pares en la línea de tiempo',
      welcomeBack: '¡Bienvenido de nuevo, {{name}}!',
      enterTimeline: 'Entrar en mi línea de tiempo',
      // Traducciones de la página de Feed
      newPost: 'Nueva Publicación',
      postPlaceholder: '¿Qué estás pensando?',
      post: 'Publicar',
      cancel: 'Cancelar',
      guestPostError: 'Los usuarios invitados no pueden crear nuevas publicaciones. Por favor, inicia sesión.',
      maxPostsReached: 'Has alcanzado tu límite diario de publicaciones.',
      guestVoteError: 'Los usuarios invitados no pueden votar. Por favor, inicia sesión.',
      loadMore: 'Cargar Más',
      noMorePosts: 'No hay más publicaciones para cargar',
      shareStory: 'Compartir Historia',
      welcomeGuest: '¡Bienvenido, invitado!',
      loginSuccess: '¡Inicio de sesión exitoso!',
      feedTitle: 'Cada publicación aquí es de alguien en su {{days}}º día de vida',
      postsRemaining: 'Todavía puedes publicar {{count}} veces hoy',
      guestPostsRemaining: 'Los usuarios invitados no pueden publicar posts. Por favor, inicia sesión.',
      shareYourStory: '¡Comparte tu estado mental y tu historia con personas de diferentes tiempos y espacios!',
      // 新增的提醒内容
      registerSuccess: '¡Registro exitoso!',
      usernameExists: 'El nombre de usuario ya existe',
      emailExists: 'El correo electrónico ya existe',
      invalidCredentials: 'Nombre de usuario o contraseña inválidos',
      serverError: 'Error del servidor, por favor intente más tarde',
      postTooLong: 'El contenido de la publicación es demasiado largo',
      postSuccess: '¡Publicación publicada con éxito!',
      postFailed: 'Error al publicar la publicación',
      noPostsAvailable: 'No hay publicaciones disponibles. ¡Sé el primero en publicar algo!',
      backToDashboard: "Volver al Panel de Control",
      confirmDeleteAccount: "Confirmar Eliminación de Cuenta",
      deleteAccountWarning: "¿Estás seguro de que quieres eliminar tu cuenta?",
      deleteAccountDataLoss: "Esta acción eliminará permanentemente todas tus publicaciones y datos relacionados. Esto no se puede deshacer.",
      confirm: "Confirmar",
      searchPosts: 'Buscar publicaciones',
      filterByMonth: 'Filtrar por mes',
      deleteSelectedPosts: 'Eliminar publicaciones seleccionadas',
      content: 'Contenido',
      createdAt: 'Creado el',
      upvotes: 'Votos positivos',
      downvotes: 'Votos negativos',
      userVote: 'Voto del usuario',
      noVote: 'Sin voto',
      forgotPassword: '¿Olvidaste tu contraseña?',
      forgotPasswordError: {
        emptyEmail: "Por favor, ingresa tu correo electrónico.",
        emailSendFailed: "Error al enviar el correo de restablecimiento de contraseña.",
        unknown: "Ocurrió un error desconocido.",
        invalidToken: "Token inválido o expirado.",
        verificationFailed: "Error al verificar el token.",
        missingToken: "Token faltante.",
        passwordMismatch: "Las contraseñas no coinciden.",
        resetFailed: "Error al restablecer la contraseña.",
      },
      forgotPasswordEmailSent: "Se ha enviado un correo de restablecimiento de contraseña.",
      resetPassword: "Restablecer Contraseña",
      sendResetEmail: "Enviar Correo de Restablecimiento",
      backToLogin: "Volver al Inicio de Sesión",
      forgotPasswordSuccess: "Tu contraseña ha sido restablecida exitosamente.",
      lifeTimeline: 'Línea de tiempo: Momentos donde las vidas se cruzan',
      fetchError: 'Error al obtener los datos de DaysLived:',
      day: 'Día {{day}}',
      postCount: 'Total {{count}} publicaciones',
      lastPostTime: 'Última hora de publicación: {{time}}',
      appName: 'TimeMassager',
      copyright: '© {{year}} TimeMassager',
      // 新增的翻译
      ResetPassword: {
        title: 'Restablecer Contraseña',
        newPassword: 'Nueva Contraseña',
        confirmNewPassword: 'Confirmar Nueva Contraseña',
        submit: 'Enviar',
        submitting: 'Enviando...',
        passwordsDoNotMatch: 'Las contraseñas no coinciden',
        success: 'Contraseña restablecida con éxito',
        failed: 'Error al restablecer la contraseña',
      },
      sendingVeriEmail: {
        registrationSuccess: '¡Registro exitoso!',
        emailSentMessage: '¡Gracias por registrar! Hemos enviado un correo electrónico de verificación.',
        clickToSendEmail: '¡Gracias por registrar! Puede enviar un correo electrónico de verificación haciendo clic en el siguiente botón.',
        checkEmailInstructions: 'Por favor, verifica tu correo electrónico y completa el último paso.',
        checkSpamFolder: '¿No recibiste el correo electrónico? Por favor, verifica tu carpeta de correo no deseado.',
        resendEmailButton: 'Reenviar correo de verificación ({{time}})',
        resendEmailButtonDefault: 'Reenviar correo de verificación',
        resendCooldown: 'Puedes reenviar el correo de verificación después de 120 segundos.', 
        resendSuccess: '¡Correo de verificación enviado! Por favor, verifica tu correo electrónico. Puedes reenviar el correo de verificación después de 120 segundos.',
        resendFailed: 'Error al reenviar el correo de verificación. Por favor, intenta más tarde.',
        backToHome: 'Volver al inicio',
        localStorageCleared: 'localStorage ha sido restablecido',
      },
      PasswordResetSent: {
        title: 'Correo de restablecimiento de contraseña enviado',
        message: 'Hemos enviado un correo de restablecimiento de contraseña a su dirección de correo electrónico',
        instruction: 'Por favor, haga clic en el enlace del correo para restablecer su contraseña. El enlace es válido por 1 hora y expirará después de ese tiempo.',
        countdown: 'La página se redirigirá automáticamente a la página de inicio en',
        seconds: 'segundos',
        backToHome: 'Volver a la página de inicio'
      },
      verifyEmail: {
        success: '¡Verificación de correo electrónico exitosa!',
        failed: '¡Verificación de correo electrónico fallida!',
      },
      setResetPassword: {
        title: 'Restablecer Contraseña',
        success: '¡Contraseña restablecida con éxito!',
        failed: 'Error al restablecer la contraseña',
        newPassword: 'Nueva Contraseña',
        confirmNewPassword: 'Confirmar Nueva Contraseña',
        submit: 'Enviar',
        submitting: 'Enviando...',
        passwordsDoNotMatch: 'Las contraseñas no coinciden',
      },
    },
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'en', // 默认语言
  fallbackLng: 'en', // 备用语言
  interpolation: {
    escapeValue: false, // React 自动防止 XSS
  },
});

export default i18n;
