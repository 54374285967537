'use client'


import React, { useState, useEffect } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Paper,
  Grid,
  Box,
  ThemeProvider,
  createTheme,
  CssBaseline,
  Select,
  MenuItem,
  SelectChangeEvent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material'
import { Timer as TimerIcon } from 'lucide-react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material'
import { useAuth } from '../../context/AuthContext';
const theme = createTheme({
  palette: {
    primary: {
      main: '#2196f3',
    },
    secondary: {
      main: '#ff4081',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Noto Sans SC', 'Noto Sans KR', 'Noto Sans JP', sans-serif",
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          textTransform: 'none',
        },
      },
    },
  },
})

const languages = [
  { code: 'en', name: 'English' },
  { code: 'zh', name: '中文' },
  { code: 'ko', name: '한국어' },
  { code: 'ja', name: '日本語' },
  { code: 'es', name: 'Español' },
]

const ProfilePage: React.FC = () => {
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState<string>(i18n.language)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const navigate = useNavigate()
  const { setIsAuthenticated, userInfo, setUserInfo, logout } = useAuth()


  useEffect(() => {
    const savedLanguage = localStorage.getItem('language')
    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage)
      setLanguage(savedLanguage)
    }
  }, [i18n])

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios
        .get(`${process.env.REACT_APP_API_BASE_URL}/api/me`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then((response) => {
          if (response.status === 200) {
            setUserInfo(response.data);
            setIsAuthenticated(true);
          } else {
            throw new Error('Failed to fetch user info');
          }
        })
        .catch((error) => {
          console.error('Failed to fetch user info:', error);
          if (error.response) {
            console.error('Error response data:', error.response.data);
            console.error('Error status:', error.response.status);
          } else {
            console.error('Error without response:', error);
          }
          setIsAuthenticated(false);
          navigate('/login');
        });
    } else {
      console.log('No token found');
      setIsAuthenticated(false);
      navigate('/login');
    }
  }, [navigate, setIsAuthenticated, setUserInfo]); // 添加依赖项
  
  

  const handleLanguageChange = (event: SelectChangeEvent) => {
    const newLang = event.target.value
    setLanguage(newLang)
    i18n.changeLanguage(newLang)
    localStorage.setItem('language', newLang)
  }

  const handleLogout = () => {
    logout();
    navigate('/')
  }

  const handleDeleteAccount = () => {
    setOpenDeleteDialog(true)
  }

  const handleConfirmDelete = () => {
    const token = localStorage.getItem('token')
    if (token) {
      axios
        .delete(`${process.env.REACT_APP_API_BASE_URL}/api/me/delete`, {
          headers: { Authorization: `Bearer ${token}` },
        })
        .then(() => {
          logout();
          navigate('/')
        })
        .catch((error) => {
          console.error('Failed to delete account:', error)
        })
    }
    setOpenDeleteDialog(false)
  }

  const handleBackToDashboard = () => {
    navigate('/dashboard');
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <AppBar position="static" color="primary">
          <Toolbar>
            <Box sx={{ mr: 2, color: 'white' }}>
              <TimerIcon />
            </Box>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: 'white' }}>
              {t('appName')}
            </Typography>
            <Select 
              value={language} 
              onChange={handleLanguageChange} 
              sx={{ mr: 2, minWidth: 120, color: 'white', '& .MuiSelect-icon': { color: 'white' } }}
            >
              {languages.map((lang) => (
                <MenuItem key={lang.code} value={lang.code}>
                  {lang.name}
                </MenuItem>
              ))}
            </Select>
            <Button color="inherit" onClick={handleLogout}>
              {t('logout')}
            </Button>
          </Toolbar>
        </AppBar>

        <Container component="main" sx={{ mt: 4, mb: 2, flexGrow: 1 }}>
          <Button
            startIcon={<ArrowBackIcon />}
            onClick={handleBackToDashboard}
            sx={{ mb: 2 }}
          >
            {t('backToDashboard')}
          </Button>
          <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
            <Typography variant="h4" component="h1" gutterBottom color="primary">
              {t('profile')}
            </Typography>
            {userInfo && (
              <Box sx={{ mt: 3 }}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1" color="textSecondary">{t('username')}:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1">{userInfo.username}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1" color="textSecondary">{t('email')}:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1">{userInfo.email}</Typography>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Typography variant="subtitle1" color="textSecondary">{t('birthdate')}:</Typography>
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <Typography variant="body1">{userInfo.birthdate}</Typography>
                  </Grid>
                </Grid>
              </Box>
            )}
            <Button
              variant="contained"
              color="inherit"
              onClick={handleDeleteAccount}
              sx={{ mt: 4, backgroundColor: 'grey.500', color: 'white', '&:hover': { backgroundColor: 'grey.700' } }}
            >
              {t('deleteAccount')}
            </Button>
          </Paper>
        </Container>

        <Box
          component="footer"
          sx={{ py: 3, px: 2, mt: 'auto', backgroundColor: (theme) => theme.palette.grey[200] }}
        >
          <Container maxWidth="sm">
            <Typography variant="body2" color="text.secondary" align="center">
              {t('copyright', { year: new Date().getFullYear() })}
            </Typography>
          </Container>
        </Box>
      </Box>

      <Dialog
        open={openDeleteDialog}
        onClose={() => setOpenDeleteDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t('confirmDeleteAccount')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('deleteAccountWarning')}
            {t('deleteAccountDataLoss')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteDialog(false)}>{t('cancel')}</Button>
          <Button onClick={handleConfirmDelete} color="error" autoFocus>
            {t('confirm')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  )
}

export default ProfilePage